import { createRef, PureComponent } from 'react';
import { getFormFields, trimAddressFields, trimCustomerAddress } from 'Util/Address';

export class CheckoutShippingPlugin extends PureComponent { 
    getSelectedShippingOption = (formFields) => {
        const formFieldsArr = Object.entries(formFields);
        // console.log('debug formFieldsArr',formFieldsArr);
        const shippingOption = formFieldsArr.filter(([key, value]) => {
            if (typeof value == 'boolean') {
                return value == true;
            }
        });
        
        return shippingOption[0] != undefined ? shippingOption[0][0] : null;
    }

    ship = (args, callback, instance) => {
        const [fields] = args;
        const {
            saveAddressInformation,
            updateShippingFields,
            addressLinesQty
        } = instance.props;
        const {
            selectedCustomerAddressId,
            selectedShippingMethod,
            selectedStoreAddress,
        } = instance.state;

        const formFields = getFormFields(fields, addressLinesQty);
        // console.log('debug formFields', formFields);
        const allowedAddressKeys = ['city', 'company', 'country_id', 'firstname', 'lastname', 'postcode', 'region_string', 'street', 'telephone'];
        const addressFields = Object.keys(formFields)
            .filter(key => allowedAddressKeys.includes(key))
            .reduce((obj, key) => {
                obj[key] = formFields[key];
                return obj;
            }, {});

        // console.log('debug addressFields', addressFields);

        // const shippingAddress = selectedCustomerAddressId
        //     ? instance._getAddressById(selectedCustomerAddressId)
        //     : trimAddressFields(formFields);
        const shippingAddress = selectedCustomerAddressId
            ? instance._getAddressById(selectedCustomerAddressId)
            : trimAddressFields(addressFields);
        
        const {
            carrier_code: shipping_carrier_code,
            method_code: shipping_method_code
        } = selectedShippingMethod;

        const data = {
            billing_address: shippingAddress,
            shipping_address: selectedStoreAddress ? instance.getStoreAddress(shippingAddress) : shippingAddress,
            shipping_carrier_code,
            shipping_method_code,
            shipping_option: formFields['Shipping-Option'] == undefined ? null : formFields['Shipping-Option']
        };

        saveAddressInformation(data);
        updateShippingFields(fields);
    }
}

const { addContainerFunctions, ship } = new CheckoutShippingPlugin();

export default {
    'Component/CheckoutShipping/Container': {
        'member-function': {
            'onShippingSuccess': {
                implementation: ship,
                position: 0
            }
        }
    },
}