/* eslint-disable */
import EstimatedDateReusable from 'Component/EstimatedDateReusable';
import EstimatedDateReusableBundle from 'Component/EstimatedDateReusableBundle';

const renderStock = (args, callback, instance) => {
    const {
        product: {
            type_id,
            sku,
            items
        } = {},
        productOrVariant: {
            sku: variantSku,
            type_id: variantTypeId
        },
        productOptionsData: {
            productOptions = []
        },
        quantity
    } = instance.props;

    if (sku && type_id && type_id === 'simple') {
        return <EstimatedDateReusable sku={ sku } qty={ quantity } isOneLine={ true } />
    }

    if (sku && variantTypeId && variantTypeId === 'configurable') {
        return '-';
    }

    if (sku && type_id && type_id === 'configurable') {
        return <EstimatedDateReusable sku={ variantSku } qty={ quantity } isOneLine={ true } />;
    }

    if (sku && type_id && type_id === 'bundle') {
        const bundleOptions = productOptions.map(({ id, value: [value] }) => ({ value, item: items.find(({ option_id }) => option_id == id) }));
        const selectedOption = bundleOptions.map(({ value, item: { options = [] } = {} }) => options.find(({ id }) => id == value));
        console.log("debug", selectedOption, selectedOption.length);
        const bundleSkus = selectedOption.map(({ product }) => {
            if (product !== null) {
                const { sku = '' } = product;
                return sku
            } else {
                return ''
            }
        }).filter(sku => sku !== '');

        return <EstimatedDateReusableBundle skus={ bundleSkus } qty={ quantity } isOneLine={ true } />;
    }

    return callback.apply(instance, args);
}

export default {
    'Component/ProductActions/Component': {
        'member-function': {
            renderStock
        }
    }
}