/* eslint-disable */
import { NavigationTabsContainer as SourceNavigationTabsContainer, mapStateToProps, mapDispatchToProps } from 'SourceComponent/NavigationTabs/NavigationTabs.container';
import { connect } from 'react-redux';
import { isSignedIn } from 'Util/Auth';
import { appendWithStoreCode } from 'Util/Url';
import { MY_ACCOUNT } from 'Component/Header/Header.config';
import browserHistory from 'Util/History';
import {
    ACCOUNT_TAB,
    MENU_TAB
} from './NavigationTabs.config';
export * from 'SourceComponent/NavigationTabs/NavigationTabs.container';

export class NavigationTabsContainer extends SourceNavigationTabsContainer {
    routeMap = {
        ...this.routeMap,
        '/account/login': { name: ACCOUNT_TAB }
    }

    onMyAccountButtonClick() {
        const { pathname } = location;
        if (!isSignedIn()) {
            if (pathname !== appendWithStoreCode(`/account/login`)) {
                browserHistory.push(appendWithStoreCode(`/account/login`));
            }
            return;
        }

        if (pathname !== appendWithStoreCode(`/${ MY_ACCOUNT }`)) {
            browserHistory.push(appendWithStoreCode(`/${ MY_ACCOUNT }`));
        }
    }

    onMenuButtonClick() {
        const { navigationState: { name } } = this.props;

        // TODO: resolve issue when coming from CMS page
        // if (name === MENU_TAB) { // if we already are in menu
            // browserHistory.push(appendWithStoreCode('/menu'));
        // } else if (this.lastSeenMenu <= 0) { // if we have not yet seen menu
            // browserHistory.push(appendWithStoreCode('/menu'));
        // } else { // otherwise go to last remembered category
            // browserHistory.go(-this.lastSeenMenu);
        // }
        browserHistory.push(appendWithStoreCode('/menu'));
        this.lastSeenMenu = 0;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationTabsContainer);