/* eslint-disable */
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import MyAccountNews from './MyAccountNews.component';
import { makeCancelable } from 'Util/Promise';
import { prepareQuery } from 'Util/Query';
import { executePost } from 'Util/Request';
import MailchimpQuery from '../../query/Mailchimp.query';
import PropTypes from 'prop-types';
import { DeviceType } from 'Type/Device';

/** @namespace Component/MyAccountNews/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
    base_media_url: state.ConfigReducer.secure_base_media_url
});

/** @namespace Component/MyAccountNews/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Component/MyAccountNews/Container */
export class MyAccountNewsContainer extends PureComponent {
    static propTypes = {
        newsletters: PropTypes.array,
        isLoading: PropTypes.bool,
        device: DeviceType.isRequired
    };

    state = {
        newsletters: [],
        isLoading: true
    };

    containerFunctions = {

    };

    __construct(props) {
        super.__construct(props);
    }


    containerProps = () => {

    };

    componentDidMount() {
        this.getMailchimpNewsletters();
    }

    getMailchimpNewsletters() {
        this.fetchData(MailchimpQuery.getQuery(), this.onSuccess, this.onError);
    }

    onSuccess = (response) => {
        const { getMailchimpCampaigns = [] } = response;
        this.setState({newsletters: getMailchimpCampaigns, isLoading: false});
    }

    onError = (error) => {
        console.error(error);
    }

    fetchData(rawQueries, onSuccess = () => {}, onError = () => {}) {
        const preparedQuery = prepareQuery(rawQueries);

        this.promise = makeCancelable(
            executePost(preparedQuery)
        );

        this.promise.promise.then(
            (response) => onSuccess(response),
            (err) => onError(err)
        );
    }

    render() {
        return (
            <MyAccountNews 
                {...this.props}
                {...this.state}
                {...this.containerFunctions}
                {...this.containerProps()}
            />
        );
    }
}

//export default MyAccountNewsContainer;
export default connect(mapStateToProps, mapDispatchToProps)(MyAccountNewsContainer);
