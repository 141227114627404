/* eslint-disable */
import { PureComponent } from 'react';

import Link from 'Component/Link';
import Loader from 'Component/Loader';
import Image from 'Component/Image';
import PropTypes from 'prop-types';
import { DeviceType } from 'Type/Device';

import './MyAccountNews.style.scss';
//import isMobile from 'Util/Mobile/isMobile';

/** @namespace Component/MyAccountNews/Component */
export class MyAccountNews extends PureComponent {
    static propTypes = {
        // eslint-disable-next-line react/no-unused-prop-types
        newsletters: PropTypes.array,
        isLoading: PropTypes.bool,
        device: DeviceType.isRequired
    };

    renderNewsBlock() {
        const {newsletters = [], isLoading, base_media_url} = this.props;
        if (newsletters.length == 0) return;
        return (
            <div block="MyAccountNews">
                <img
                    block="MyAccountNews"
                    elem="NewsImage"
                    src={`${base_media_url}b2b-assets/dashboard/newsletter.png`}
                />
                <div block="MyAccountNews" elem="Text">
                    <Link to={newsletters[0].archive_url}>
                        <h4 block="MyAccountNews" elem="Title">{newsletters[0].title}</h4>
                    </Link>
                    <h5 block="MyAccountNews" elem="Date">{this.parseDate(newsletters[0].date).toLocaleDateString('en-GB').replace(/\//g, '.')}</h5>
                </div>
            </div>
        );
    }

    renderNewsItem(newsletter, index) {
        const { device } = this.props;
        var isIndexAboveLimit = index >= 8;

        if (device.isMobile) {
            isIndexAboveLimit = index >= 3;
        }

        return (
            <>            
                { isIndexAboveLimit ?
                    <tr block="Table" elem="Item hidden" key={ index } mix={{ block: 'MyAccountNews', elem: 'Item' }}>
                        <td
                          block="Table"
                          elem="ItemData"
                          mods={{ align_left: true, align_leftMobile: true, align_leftTablet: true }}
                          mix={{ block: 'MyAccountNews', elem: 'ItemTitle' }}
                        >
                            <Link to={newsletter.archive_url}>{newsletter.title}</Link>
                        </td>
                        <td
                          block="Table"
                          elem="ItemData"
                          mods={{ align_right: true, align_leftMobile: true, align_leftTablet: true }}
                          mix={{ block: 'MyAccountNews', elem: 'ItemDate' }}
                        >
                            {newsletter.date}
                        </td>
                    </tr>
                : 
                    <tr block="Table" elem="Item" key={ index } mix={{ block: 'MyAccountNews', elem: 'Item' }}>
                        <td
                          block="Table"
                          elem="ItemData"
                          mods={{ align_left: true, align_leftMobile: true, align_leftTablet: true }}
                          mix={{ block: 'MyAccountNews', elem: 'ItemTitle' }}
                        >
                            <Link to={newsletter.archive_url}>{newsletter.title}</Link>
                        </td>
                        <td
                          block="Table"
                          elem="ItemData"
                          mods={{ align_right: true, align_leftMobile: true, align_leftTablet: true }}
                          mix={{ block: 'MyAccountNews', elem: 'ItemDate' }}
                        >
                            {this.parseDate(newsletter.date).toLocaleDateString('en-GB')}
                        </td>
                    </tr>
                }
            </>
        );
    }

    parseDate(date) {
        if (date == null) return null
        const dateArr = date.split(/[^0-9]/);
        const parsedDate = new Date(dateArr[0], dateArr[1] - 1, dateArr[2])
        return parsedDate;
    }

    render() {
        return (
            <>
                { this.renderNewsBlock() }
            </>
        );
    }
}

export default MyAccountNews;
