/* eslint-disable */
import { lazy } from 'react';
import { UrlRewrites as SourceUrlRewrites } from 'SourceRoute/UrlRewrites/UrlRewrites.component';
import {
    TYPE_BUILDER_CATEGORY,
    TYPE_BUILDER_PRODUCT,
    TYPE_CATEGORY,
    TYPE_CMS_PAGE,
    TYPE_NOTFOUND,
    TYPE_PRODUCT
} from './UrlRewrites.config';
import NoMatch from 'Route/NoMatch';
export * from 'SourceRoute/UrlRewrites/UrlRewrites.component';

export const ProductPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "product" */ 'Route/ProductPage'));
export const CategoryPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "category" */ 'Route/CategoryPage'));
export const CmsPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Route/CmsPage'));
// export const BuilderProductPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "builderProduct" */ 'Route/BuilderProductPage'));
// export const BuilderCategoryPage = lazy(() => import(/* webpackMode: "lazy", webpackChunkName: "builderCategory" */ 'Route/BuilderCategoryPage'));

export class UrlRewrites extends SourceUrlRewrites {
    renderContent() {
        const { props, type } = this.props;

        switch (type) {
        case TYPE_BUILDER_PRODUCT:
        case TYPE_PRODUCT:
            return <ProductPage { ...props } />;
        case TYPE_CMS_PAGE:
            return <CmsPage { ...props } />;
        case TYPE_BUILDER_CATEGORY:
        case TYPE_CATEGORY:
            return <CategoryPage { ...props } />;
        case TYPE_NOTFOUND:
            return <NoMatch { ...props } />;
        default:
            return this.renderDefaultPage();
        }
    }
}

export default UrlRewrites;