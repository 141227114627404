/* eslint-disable */
import MyAccountDashboard from 'SourceComponent/MyAccountDashboard/MyAccountDashboard.component';

import PropTypes from 'prop-types';
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import MyAccountAddressTable from 'Component/MyAccountAddressTable';
import MyAccountCustomerTable from 'Component/MyAccountCustomerTable';
import { MY_ACCOUNT_URL } from 'Route/MyAccount/MyAccount.config';
import { ADDRESS_BOOK, customerType } from 'Type/Account';

import './MyAccountProfile.style';

/** @namespace Component/MyAccountProfile/Component */
export class MyAccountProfile extends MyAccountDashboard {
    static propTypes = {
        customer: customerType.isRequired,
        getDefaultAddress: PropTypes.func.isRequired,
        handleLogout: PropTypes.func.isRequired
    };

    renderNoDefaultAddressConfigured(name) {
        return (
            <div key={ name }>
                <p block="MyAccountProfile" elem="Info">{ __('No %s address configured.', name) }</p>
                { this.renderLinkToAddressBook() }
            </div>
        );
    }

    renderLinkToAddressBook() {
        return (
            <p block="MyAccountProfile" elem="Info">
                <Link to={ `${MY_ACCOUNT_URL}/${ADDRESS_BOOK}` }>
                    { __('Go to "Address Book", to configure them!') }
                </Link>
            </p>
        );
    }

    renderDefaultAddressTable(isBilling) {
        const { getDefaultAddress } = this.props;
        const name = isBilling ? __('Invoicing Address') : __('Shipping Address(es)');
        const address = getDefaultAddress(isBilling);
        if (!address) {
            return this.renderNoDefaultAddressConfigured(name);
        }

        return (
            <>
                <MyAccountAddressTable key={ name }
                  address={ address }
                  showAdditionalFields
                />
            </>
        );
    }

    renderNoAddresses() {
        return (
            <div>
                <p block="MyAccountProfile" elem="Info">{ __('You have no configured addresses.') }</p>
                { this.renderLinkToAddressBook() }
            </div>
        );
    }

    renderAddressTables() {
        const { customer: { addresses = [] } } = this.props;

        if (!addresses.length) {
            return this.renderNoAddresses();
        }

        return (
            <>
                <div block="MyAccountProfile" elem="AddressTitle">Billing Address</div>
                { this.renderDefaultBillingAddress() }
                <div block="MyAccountProfile" elem="AddressTitle">Shipping Address(es)</div>
                { this.renderDefaultShippingAddress() }
                
            </>
        );
    }

    renderDefaultBillingAddress() {
        // Render default billing address
        return (
            <>
                <div
                  block="MyAccountProfile"
                  elem="AddressInvoicing"
                >
                    { this.renderDefaultAddressTable(true) }
                    { this.renderAddressActions() }
                </div>
                <div block="MyAccountProfile" elem="GridFill" />
            </>
        );
    }

    renderDefaultShippingAddress() {
        // Render default shipping address
        return (
            <>
                <div
                  block="MyAccountProfile"
                  elem="AddressShipping"
                >
                    { this.renderDefaultAddressTable() }
                </div>
            </>
        );
    }

    renderShippingAddresses() {
        // Render all addresses except default shipping
        return (
            <>
                <div
                  block="MyAccountProfile"
                  elem="AddressShipping"
                >
                    { this.renderDefaultAddressTable() }
                </div>
            </>
        );
    }

    renderAddressActions() {
        return (
            <>
                <Link
                  to="/my-account/address-book"
                  block="Button"
                  mix={{ block: 'MyAccountProfile', elem: 'Button' }}
                >
                    { __('Go to address book') }
                </Link>
            </>
        );
    }

    renderCustomerTable() {
        const { customer, handleLogout } = this.props;

        return (
            <>
                <button type="button" block="Button" onClick={handleLogout} mix={{ block: 'MyAccountProfile', elem: 'LogoutButton' }}>{ __('Logout') }</button>
                <div block="MyAccountProfile" elem="CustomerDataTitle">My Details</div>
                <div block="MyAccountProfile" elem="CustomerData">
                    <MyAccountCustomerTable
                    customer={ customer }
                    />
                </div>
                <div block="MyAccountProfile" elem="GridFill" />
            </>
        );
    }

    renderAgreements() {
        return (
            <div block="MyAccountProfile" elem="Agreements">
                <label block="MyAccountProfile" elem="AgreementWrapper" mix={{ block: 'Form', elem: 'CheckBox' }}>
                    <input type="checkbox" id="DealerAgreementSigned" name="DealerAgreementSigned" block="MyAccountProfile" elem="AgreementCheckBox" />
                    <span block="Form" elem="CheckMark" />
                    Dealer Agreement Signed?
                    <Link to="/my-account/media-and-documents" block="MyAccountProfile" elem="AgreementLink">Download Dealer Agreement</Link>
                </label>
                <label block="MyAccountProfile" elem="AgreementWrapper" mix={{ block: 'Form', elem: 'CheckBox' }}>
                    <input type="checkbox" id="SepaAgreementSigned" name="SepaAgreementSigned" block="MyAccountProfile" elem="AgreementCheckBox" />
                    <span block="Form" elem="CheckMark" />
                    SEPA Agreement Signed?
                    <Link to="/my-account/media-and-documents" block="MyAccountProfile" elem="AgreementLink">Download SEPA Agreement</Link>
                </label>
            </div>
        );
    }

    render() {
        const { customer } = this.props;

        return (
            <div block="MyAccountProfile">
                <Loader isLoading={ !Object.keys(customer).length } />
                { this.renderCustomerTable() }
                {/* { this.renderAgreements() } */}
                { this.renderAddressTables() }
                <div block="MyAccountProfile" elem="SupportButtonBuffer" />
                { this.renderCustomerPopup() }
            </div>
        );
    }
}

export default MyAccountProfile;