/* eslint-disable */
import TextPlaceholder from 'Component/TextPlaceholder'

/** @namespace Component/EstimatedDateReusable/Component */
export class EstimatedDateReusable extends PureComponent {
    render() {
        const { dateInStock, isLoading, isSimple, isShown, showDisclaimer, disclaimer, isOneLine } = this.props;

        if (!isSimple && isLoading) {
            return <TextPlaceholder length='block' />
        }

        if (isSimple && !isShown) {
            return null;
        }

        if (isOneLine) {
            return (
                <div block="EstimatedDate" mods={ { isSimple } }>
                    { dateInStock }
                    { showDisclaimer && <> - { disclaimer }</> }
                </div>
            )
        }

        return (
            <div block="EstimatedDate" mods={ { isSimple } }>
                { dateInStock }
                { showDisclaimer && <><br/>{ disclaimer }</> }
            </div>
        )
    }
}

export default EstimatedDateReusable;