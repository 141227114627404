/* eslint-disable */
import MyAccountProductBacklog from '../component/MyAccountProductBacklog';
export const PRODUCT_BACKLOG = 'product-backlog';

const addToTab = (member, instance) => {
    return ({
        [PRODUCT_BACKLOG]: {
            url: '/product-backlog',
            name: __('Product Backlog')
        },
        ...member,
    });
}

const addToRenderMap = (member, instance) => {
    return({
        ...member,
        [PRODUCT_BACKLOG]: MyAccountProductBacklog,
    });
}

export default {
    'Route/MyAccount/Container': {
        'member-property': {
            'tabMap': {
                implementation: addToTab,
                position: 3
            }
        }
    },
    'Route/MyAccount/Component': {
        'member-property': {
            'renderMap': {
                implementation: addToRenderMap,
                position: 3
            }
        }
    }
}