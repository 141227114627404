/* eslint-disable */

 import { parseDate } from '@mailchimp/mailchimp_marketing/src/ApiClient';
import BrowserDatabase from 'Util/BrowserDatabase';
 import { ONE_MONTH_IN_SECONDS } from 'Util/Request/QueryDispatcher';
 
 import { GET_ORDER_LIST } from './Order.action';
 
 export const ORDERS = 'orders';
 
 /** @namespace Store/Order/Reducer/getFormattedDate */
 export const getFormattedDate = (rawDate = '') => {
    const dateArr = rawDate.split(/[^0-9]/);
     const date = new Date(dateArr[0], dateArr[1] - 1, dateArr[2], dateArr[3], dateArr[4], dateArr[5]);
     const RADIX = 10;
 
     const addLeadingZero = (value) => (value < RADIX ? `0${value}` : value);
 
     const day = addLeadingZero(date.getDate());
     const month = addLeadingZero(date.getMonth() + 1);
 
     return `${day}.${month}.${date.getFullYear()}`;
 };
 
 /** @namespace Store/Order/Reducer/formatOrders */
 export const formatOrders = (orders) => orders.reduce((acc, order) => {
     const { base_order_info } = order;
     const { created_at } = base_order_info;
     const formattedDate = getFormattedDate(created_at);
 
     return [
         ...acc,
         {
             ...order,
             base_order_info: {
                 ...order.base_order_info,
                 created_at: formattedDate
             }
         }
     ];
 }, []);
 
 export const orderList = BrowserDatabase.getItem(ORDERS) || [];
 
 /** @namespace Store/Order/Reducer/getInitialState */
 export const getInitialState = () => ({
     orderList,
     isLoading: !orderList.length
 });
 
 /** @namespace Store/Order/Reducer */
 export const OrderReducer = (
     state = getInitialState(),
     action
 ) => {
     const {
         type,
         orderList,
         status
     } = action;
 
     switch (type) {
     case GET_ORDER_LIST:
         const { items = [] } = orderList;
         const formattedOrders = formatOrders(items);
         
         BrowserDatabase.setItem(formattedOrders, ORDERS, ONE_MONTH_IN_SECONDS);
 
         return {
             ...state,
             isLoading: status,
             orderList: formattedOrders
         };
 
     default:
         return state;
     }
 };
 
 export default OrderReducer;
 