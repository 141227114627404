/* eslint-disable */
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { showPopup } from 'Store/Popup/Popup.action';
import { ETA_POPUP_ID } from '../MyAccountProductBacklogPopup/MyAccountProductBacklogPopup.config';
import ProductBacklogQuery from 'Query/ProductBacklog.query';
import { fetchQuery } from 'Util/Request';

import MyAccountProductBacklog from './MyAccountProductBacklog.component';


export const mapStateToProps = (state) => ({
    disclaimer: state.ConfigReducer.eta_disclaimer,
    showWithin: state.ConfigReducer.stock_warehouses_frontend_show_eta_within,
    showWithinText: state.ConfigReducer.stock_warehouses_frontend_show_eta_within_text
});

export const mapDispatchToProps = (dispatch) => ({
    showPopup: (payload) => dispatch(showPopup(ETA_POPUP_ID, payload))
});

export class MyAccountProductBacklogContainer extends PureComponent {
    state = {
        isLoading: true, 
        productList: [],
        filteredProductList: [],
        product_name: null,
        sku: null,
        order_notes: null,
        addedEventListner: false
    }

    containerFunctions = {
        showPopup: this.showPopup.bind(this),
        onTextFieldChange: this.onTextFieldChange.bind(this),
        onApplyFilterClick: this.onApplyFilterClick.bind(this),
        parseDate: this.parseDate.bind(this)
    }

    componentDidMount() {
        fetchQuery(ProductBacklogQuery.getQuery()).then(({getProductBacklog: data}) => {
            data.sort((a, b) => {
                if (a.nextDeliveryDate == null) return 1;
                if (b.nextDeliveryDate == null) return -1;
    
                const dateA = this.parseDate(a.nextDeliveryDate);
                const dateB = this.parseDate(b.nextDeliveryDate);
                if (dateA > dateB) {
                    return 1;
                } else {
                    return -1
                }
            });
            this.setState({isLoading: false, productList: data, filteredProductList: data});
        }, (error) => console.error('debug',error));

        this.addEventListners();
    }

    componentDidUpdate() {
        this.addEventListners();
    }

    componentWillUnmount() {
        const { addedEventListner } = this.state;
        const inputs = document.querySelectorAll('.Form-Inputs');
        if (addedEventListner) {
            inputs.forEach(input => {
                input.removeEventListener('keyup', this.onEnterPressed.bind(this));
            });
        }
        this.setState({addedEventListner: false})
    }

    parseDate(date) {
        if (date == null) return null
        const dateArr = date.split(/[^0-9]/);
        const parsedDate = new Date(dateArr[0], dateArr[1] - 1, dateArr[2])
        return parsedDate;
    }

    addEventListners() {
        const { addedEventListner } = this.state;
        const inputs = document.querySelectorAll('.Form-Input');
        if (!addedEventListner) {
            inputs.forEach(input => {
                input.addEventListener('keyup', this.onEnterPressed.bind(this));
            });
        }
        this.setState({addedEventListner: true});
    }

    onEnterPressed(event) {
        if (event.keyCode === 13) {
            event.preventDefault();
            this.onApplyFilterClick();
        }
    }

    showPopup(product) {
        const { showPopup } = this.props;
        return showPopup({product: product});
    }

    onTextFieldChange(value, textField) {
        switch (textField) {
            case 'product_name':
                this.setState({ product_name: value });
                break;
            case 'sku':
                this.setState({ sku: value });
                break;
            case 'order_notes':
                this.setState({ order_notes: value });
                break;

            default:
                break;
        }
    }

    onApplyFilterClick() {
        const { productList, product_name, sku, order_notes } = this.state;
        if (!product_name && !sku && !order_notes) {
            this.setState({ filteredProductList: productList });
        } else {
            this.filterProductList(product_name, sku, order_notes);
        }
    }
    
    filterProductList(productName = null, sku = null, orderComment) {
        const { productList } = this.state
        var filteredList = productList;
        
        if (productName) {
            filteredList = filteredList.filter(product => {
                return product.productName.toLowerCase().includes(productName.toLowerCase());
            });
        }
        
        if (sku) {
            filteredList = filteredList.filter(product => {
                return product.sku.toLowerCase().includes(sku.toLowerCase());
            });
        }

        if (orderComment) {
            filteredList = filteredList.filter(product => {
                const { orders = [] } = product;
                const filteredOrderComment = orders.filter(order => {
                    if (order.orderComment == null) return false;
                    return order.orderComment.includes(orderComment)
                });
                return filteredOrderComment.length;
            });
        }

        this.setState({filteredProductList: filteredList});
    }

    render() {
        return (
            <MyAccountProductBacklog
                {...this.props}
                {...this.state}
                {...this.containerFunctions}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountProductBacklogContainer);