/* eslint-disable */
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import MyAccountProductBackordersComponent from './MyAccountProductBackorders.component';
import ProductBacklogQuery from 'Query/ProductBacklog.query';
import { ETA_POPUP_ID } from '@hoeks-b2b/product-backlog/src/component/MyAccountProductBacklogPopup/MyAccountProductBacklogPopup.config'

import { showPopup } from 'Store/Popup/Popup.action';
import { fetchQuery } from 'Util/Request';

export const mapStateToProps = (state) => ({});

export const mapDispatchToProps = (dispatch) => ({
    showPopup: (payload) => dispatch(showPopup(ETA_POPUP_ID, payload))
});

export class MyAccountProductBackordersContainer extends PureComponent {
    state = {
        isLoading: true,
        productList: []
    }

    containerFunctions = {
        getNextDeliveryDate: this.getNextDeliveryDate.bind(this),
        getNumberOfBackorders: this.getNumberOfBackorders.bind(this),
        showPopup: this.showPopup.bind(this)
    }

    componentDidMount() {
        fetchQuery(ProductBacklogQuery.getQuery()).then(({getProductBacklog: data}) => {
            this.setState({isLoading: false, productList: data});
        }, (error) => console.error('debug',error));
    }

    getNextDeliveryDate(date) {
        const parsedDate = this.parseDate(date);
        if (parsedDate) {
            return parsedDate.toLocaleDateString('en-GB');
        }
        return null;
    }

    parseDate(date) {
        if (date == null) return null
        const dateArr = date.split(/[^0-9]/);
        const parsedDate = new Date(dateArr[0], dateArr[1] - 1, dateArr[2])
        return parsedDate;
    }

    getNumberOfBackorders(orders = []) {
        var backOrders = 0;
        orders.forEach(({ eta, itemQty }) => {
            backOrders = backOrders + parseInt(itemQty);
            // if (eta == null) {
            //     backOrders = backOrders + parseInt(itemQty);
            // } else if (new Date(eta) > new Date()) {
            //     backOrders = backOrders + parseInt(itemQty);
            // }
        });
        return backOrders;
    }

    showPopup(product) {
        const { showPopup } = this.props;
        return showPopup({product: product});
    }

    render() {
        return (
            <MyAccountProductBackordersComponent 
                {...this.props}
                {...this.state}
                {...this.containerFunctions}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountProductBackordersContainer);