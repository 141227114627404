/* eslint-disabled */

import { CheckoutSuccess as SourceCheckoutSuccess } from 'SourceComponent/CheckoutSuccess/CheckoutSuccess.component';
import Link from 'Component/Link';

export class CheckoutSuccess extends SourceCheckoutSuccess {
    renderButtons() {
        return (
            <div block="CheckoutSuccess" elem="ButtonWrapper">
                <Link
                  block="Button"
                  mix={ { block: 'CheckoutSuccess', elem: 'ContinueButton' } }
                  to="/shop"
                >
                    { __('Continue shopping') }
                </Link>
            </div>
        );
    }
}

export default CheckoutSuccess;
