// TODO update this import when renamed
import { UPDATE_SHIPPING_OPTIONS } from './ShippingOptions.action';

/** @namespace Hoeks/ShippingOptions/Store/ShippingOptions/Reducer/getInitialState */
export const getInitialState = () => ({
    // TODO set initial state
    options: []
});

/** @namespace Hoeks/ShippingOptions/Store/ShippingOptions/Reducer/ShippingOptionsReducer */
export const ShippingOptionsReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case UPDATE_SHIPPING_OPTIONS:
        const { options } = action;

        return {
            ...state,
            options: options
            // TODO implement payload handling
        };

    default:
        return state;
    }
};

export default ShippingOptionsReducer;
