/* eslint-disable */

// imports the original component from vendor folder
import { Logo as SourceLogo } from 'SourceComponent/Logo/Logo.component';

/** @namespace Component/Logo/Component */
export class Logo extends SourceLogo {
    renderPlaceholderLogo() {
        const logo_alt = 'Larry vs Harry logo';
        return (
            <img
                src={ '/media/b2b-assets/logo/logo-alt.png' }
                alt={ logo_alt }
                title={ logo_alt }
                loading='lazy'
                className='Image-Image'
            />
        );
    }
}

export default Logo;
