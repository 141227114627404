/* eslint-disable */
import { getOrderList } from '@scandipwa/scandipwa/src/store/Order/Order.action';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import MyAccountRecentOrdersComponent from './MyAccountRecentOrders.component';

export const OrderDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Order/Order.dispatcher'
);

export const mapStateToProps = (state) => ({
    orderList: state.OrderReducer.orderList,
    countries: state.ConfigReducer.countries,
    currency_code: state.ConfigReducer.currencyData.current_currency_code
});

export const mapDispatchToProps = (dispatch) => ({
    getOrderList: () => OrderDispatcher.then(
        ({ default: dispatcher }) => dispatcher.requestOrders(dispatch)
    )
});

export class MyAccountRecentOrdersContainer extends PureComponent {
    componentDidMount() {
        const { getOrderList } = this.props;
        getOrderList()
    }

    containerFunctions = {

    }

    render() {
        return (
            <MyAccountRecentOrdersComponent 
                { ...this.state }
                { ...this.props }
                { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountRecentOrdersContainer);