/* eslint-disable */
import { HeaderContainer as SourceHeaderContainer, mapStateToProps as sourceMapStateToProps, mapDispatchToProps } from 'SourceComponent/Header/Header.container';
import { connect } from 'react-redux';
import { SEARCH } from 'SourceComponent/Header/Header.config';
export * from 'SourceComponent/Header/Header.container';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';
import { STORE_CONFIG_KEY } from 'Component/StoreSwitcher/StoreSwitcher.config';

/** @namespace Component/Header/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    searchQuery: state.SearchBarReducer.searchQuery,
    storeCode: state.ConfigReducer.code,
    base_link_url: state.ConfigReducer.base_link_url,
    base_url: state.ConfigReducer.base_url,
    requestedUrl: state.UrlRewritesReducer.requestedUrl,
    breadcrumbs: state.BreadcrumbsReducer.breadcrumbs,
    isSignedIn: state.MyAccountReducer?.isSignedIn
});

/** @namespace Component/Header/Container */
export class HeaderContainer extends SourceHeaderContainer {
    __construct(props) {
        super.__construct(props);

        this.state = {
            ...this.state,
            userCountry: '',
            storeCode: '',
            isStoreSwitcherClosed: false,
            closeStoreSwitcher: this.closeStoreSwitcherBanner
        }
    }

    containerFunctions = {
        onBackButtonClick: this.onBackButtonClick.bind(this),
        onCloseButtonClick: this.onCloseButtonClick.bind(this),
        onSearchBarFocus: this.onSearchBarFocus.bind(this),
        onClearSearchButtonClick: this.onClearSearchButtonClick.bind(this),
        onMyAccountButtonClick: this.onMyAccountButtonClick.bind(this),
        onSearchBarChange: this.onSearchBarChange.bind(this),
        onClearButtonClick: this.onClearButtonClick.bind(this),
        onEditButtonClick: this.onEditButtonClick.bind(this),
        onMinicartButtonClick: this.onMinicartButtonClick.bind(this),
        onOkButtonClick: this.onOkButtonClick.bind(this),
        onCancelButtonClick: this.onCancelButtonClick.bind(this),
        onSearchOutsideClick: this.onSearchOutsideClick.bind(this),
        onMyAccountOutsideClick: this.onMyAccountOutsideClick.bind(this),
        onMinicartOutsideClick: this.onMinicartOutsideClick.bind(this),
        onSignIn: this.onSignIn.bind(this),
        shareWishlist: this.shareWishlist.bind(this),
        handleOnSwitchStoreClick: this.handleOnSwitchStoreClick.bind(this),
        hideActiveOverlay: this.props.hideActiveOverlay
    };

    componentDidMount() {
        super.componentDidMount();
        const {
            searchQuery,
            storeCode,
            isSignedIn,
            breadcrumbs
        } = this.props;

        // console.log("Debug", isSignedIn, breadcrumbs.length);
        if (!isSignedIn) {
            // window.location = `${window.location.origin}/${storeCode}/`
        }

        if (searchQuery) {
            this.setState(state => ({
                ...state,
                searchCriteria: searchQuery
            }))
        }

        fetch('https://get.geojs.io/v1/ip/country.json')
            .then(response => response.json())
            .then(({ country }) => this.setState(state => ({...state, userCountry: country})))

        this.setState(state => ({...state, storeCode }));
    }

    componentDidUpdate(prevProps) {
        super.componentDidUpdate(prevProps);

        const {
            searchQuery,
            storeCode,
            breadcrumbs,
            base_link_url,
            isSignedIn
        } = this.props;

        var isChangePasswordPage = false;

        breadcrumbs.forEach(breadcrumb => {
            if (breadcrumb.name == 'Change password') {
                
                isChangePasswordPage = true;
            }
        });

        if (!isSignedIn && breadcrumbs.length != 0 && isChangePasswordPage == false) {
            // console.log("Debug", isSignedIn, breadcrumbs.length);
            window.location = `${window.location.origin}/${storeCode}/`
        }

        const {
            searchQuery: prevSearchQuery,
            storeCode: prevStoreCode
        } = prevProps;

        if (searchQuery !== prevSearchQuery) {
            this.setState(state => ({
                ...state,
                searchCriteria: searchQuery
            }))
        }

        if (breadcrumbs[0] != undefined) {
            const changedStoreView = this.getCookie('chagedStoreView');
            if (changedStoreView && breadcrumbs[0].name == 'Not Found') {
                this.eraseCookie('chagedStoreView');
                // window.location = window.location.origin.includes('localhost') ? window.location.origin + `/${storeCode}` : base_link_url;
            } else if (changedStoreView) {
                this.eraseCookie('chagedStoreView');
            }
        }

        if (storeCode !== prevStoreCode) {
            this.setState(state => ({...state, storeCode}));
        }
    }

    closeStoreSwitcherBanner = () => {
        this.setState(state => ({...state, isStoreSwitcherClosed: true}));
    }

    getRequestedPath() {
        const { requestedUrl = "", storeCode = "" } = this.props;
        const path = requestedUrl == "" ? window.location.pathname : requestedUrl;
        
        // console.log('test', path);
        // console.log('test', path.replace('/' + storeCode, ''));
        return path.replace('/' + storeCode, '')
    }

    handleOnSwitchStoreClick() {
        const { base_url } = this.props;
        const path = this.getRequestedPath();
        this.setCookie("chagedStoreView", true, 1);
        // console.log(path);
        BrowserDatabase.deleteItem(STORE_CONFIG_KEY);
        window.location = window.location.origin.includes('localhost') ? window.location.origin + `/da_partner${path}` : base_url + `da${path}`;
    }

    setCookie(name, value, hours) {
        var expires = '';
        if (hours) {
            const date = new Date();
            date.setTime(date.getTime() + (hours * 60 * 60 * 1000));
            expires = '; expires=' + date.toUTCString();
        }

        document.cookie = name + '=' + (value || '') + expires + '; path=/';
    }

    getCookie(name) {
        const nameEQ = name + '=';
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    eraseCookie(name) {
        document.cookie = name + '=; Max-Age=-99999999;';
    }

    hideSearchOverlay() {
        const { hideActiveOverlay, activeOverlay } = this.props;

        // this.setState({ searchCriteria: '' });

        document.activeElement.blur();

        if (activeOverlay === SEARCH) {
            hideActiveOverlay();
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);