// imports the original component from vendor folder
import { Menu as SourceMenu } from 'SourceComponent/Menu/Menu.component';
import { getSortedItems } from 'Util/Menu';

// import new extended styles file
import './Menu.override.style.scss';

export class Menu extends SourceMenu {
    renderAdditionalInformation(checkMobile = false) {
        const { device } = this.props;
        if (checkMobile && !device.isMobile) {
            return null;
        }

        return (
            <div
              block="Menu"
              elem="SelectFields"
            >
                { this.renderStoreSwitcher() }
                { this.renderCurrencySwitcher() }
            </div>
        );
    }

    renderTopLevel() {
        const { menu } = this.props;
        const categoryArray = Object.values(menu);

        if (!categoryArray.length) {
            return null;
        }

        const [{ children, title: mainCategoriesTitle }] = categoryArray;
        const childrenArray = getSortedItems(Object.values(children));

        // console.log(childrenArray);

        return (
            <>
                <div block="Menu" elem="MainCategories">
                    <ul
                      block="Menu"
                      elem="ItemList"
                      mods={ { type: 'main' } }
                      aria-label={ mainCategoriesTitle }
                    >
                        { childrenArray.map(this.renderFirstLevel) }
                    </ul>
                    { this.renderAdditionalInformation(true) }
                </div>
                { this.renderSubMenuDesktop(children) }
            </>
        );
    }
}

export default Menu;
