/* eslint-disable */
import { Field } from 'Util/Query';

export class ProductBacklogQuery {
    getQuery() {
        return new Field('getProductBacklog')
                    .addFieldList(this.getProductBacklogFields());
    }

    getProductByIdAndSku(id, sku) {
        return new Field('getProductBacklog')
            .addArgument('id', 'String', id)
            .addArgument('sku', 'String', sku)
            .addFieldList(this.getProductBacklogFields());
    }

    getProductBySku(id) {
        return new Field('getProductBacklog')
            .addArgument('id', 'String', id)
            .addFieldList(this.getProductBacklogFields());
    }

    getProductBacklogFields() {
        return [
            'image',
            'sku',
            'productName',
            'productUrl',
            'nextDeliveryDate',
            this.getEtasField(),
            this._getSelectedOptionsField(),
            this.getChildItemsField()
        ]
    }

    _getSelectedOptionsField() {
        return new Field('selectedOptions').addFieldList(this._getSelectedOptionsFieldlist());
    }

    _getSelectedOptionsFieldlist() {
        return [
            'label',
            'value'
        ];
    }

    getEtasField() {
        return new Field('orders')
                    .addFieldList(this.getEtasFields());
    }

    getEtasFields() {
        return [
            'orderDate',
            'orderId',
            'orderComment',
            'orderUrl',
            'itemQty',
            'eta'
        ];
    }

    getChildItemsField() {
        return new Field('childItems')
                    .addFieldList(this.getChildItemsFields());
    }

    getChildItemsFields() {
        return [
            'image',
            'sku',
            'productName'
        ]
    }
}

export default new ProductBacklogQuery();
