/* eslint-disable */
import { PureComponent } from 'react';
import { Field as SourceField }
    from 'SourceComponent/Field/Field.component';
import FieldInput from 'Component/FieldInput';

// import new extended styles file
import './Field.override.style.scss';

/**
 * Input fields component
 * @class Field
 * @namespace Component/Field/Component
 */
export class Field extends SourceField {
    renderRadioButton() {
        const {
            id,
            label,
            onClick,
            onChange,
            checked,
            image = null,
            recommended = null
        } = this.props;
        // console.log(`ID: ${id}, Checked: ${checked}`);
        // console.log(this.props);

        return (
            <div
                block="Field"
                elem="FieldContentContainer"
            >
                <FieldInput
                    {...this.props}
                    type="radio"
                    onClick={ onClick }
                />
                { image }
                { recommended }
                <label htmlFor={ id } />
            </div>
        )
    }

    renderCheckbox() {
        const {
            id,
            onChangeCheckbox,
            image = null,
            recommended = null
        } = this.props;
        
        return (
            <div
                block="Field"
                elem="FieldContentContainer"
            >
                <FieldInput
                  { ...this.props }
                  type="checkbox"
                  onChange={ onChangeCheckbox }
                />
                { image }
                { recommended }
                <label htmlFor={ id } />
            </div>
        );
    }

    renderTypeNumber() {
        const {
            min,
            max,
            value,
            onKeyEnterDown,
            handleChange,
            disabled
        } = this.props;

        const maxBtnDisabled = disabled ? disabled : +value === max;
        const minBtnDisabled = disabled ? disabled : +value === min;

        return (
            <>
                <FieldInput
                  { ...this.props }
                  type="number"
                  readOnly
                  // eslint-disable-next-line react/jsx-no-bind
                //   onChange={ (e) => handleChange(e.target.value, false) }
                  onKeyDown={ onKeyEnterDown }
                />
                <button
                  disabled={ maxBtnDisabled }
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => handleChange(+value + 1) }
                />
                <button
                  disabled={ minBtnDisabled }
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => handleChange(+value - 1) }
                />
            </>
        );
    }

    renderLabel() {
        const { id, label, validation } = this.props;
        const isRequired = validation.includes('notEmpty');

        if (!label) {
            return null;
        }

        return (
            <label
              block="Field"
              elem="Label"
              mods={ { isRequired, isBilling: id == "default_billing" } }
              htmlFor={ id }
            >
                { label }
            </label>
        );
    }
}

export default Field;