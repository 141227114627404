/* eslint-disable */

import { ProductListQuery as SourceProductListQuery } from 'SourceQuery/ProductList.query';
import { Field } from 'Util/Query';
import BrowserDatabase from 'Util/BrowserDatabase';
import { CUSTOMER } from 'Store/MyAccount/MyAccount.dispatcher';
// export * from 'SourceQuery/ProductList.query';
/**
 * Product List Query
 * @class ProductListQuery
 * @namespace Query/ProductList
 */
export class ProductListQuery extends SourceProductListQuery {
  getQuery(options) {
    const customer = BrowserDatabase.getItem(CUSTOMER);
    if (customer && customer.id) {
      options.args.customerId = customer.id;
    } else {
      const min = Math.ceil(1000000);
      const max = Math.floor(2000000);
      options.args.customerId = Math.floor(Math.random() * (max - min) + min);
    }
    return super.getQuery(options);
  }

  _getArgumentsMap() {
    return {
      customerId: {
        type: 'Int!',
        handler: (option) => option
      },
      ...super._getArgumentsMap()
    }
  }

  _getProductInterfaceFields(isVariant, isForLinkedProducts = false) {
    // console.log('query',isForLinkedProducts);
    return [
      ...super._getProductInterfaceFields(isVariant, isForLinkedProducts),
      'dealerPrice',
      'distroPrice',
      'RRP',
      'backorders',
      'eta',
      this.getStockItemField()
    ];
  }

  getStockItemField() {
    return new Field('stock_item')
      .addFieldList(['max_sale_qty']);
  }
}

export default new ProductListQuery();