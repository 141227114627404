/* eslint-disable */
import DataContainer from 'Util/Request/DataContainer';
import DatesInStockQuery from '../../query/DatesInStock.query';
import { makeCancelable } from 'Util/Promise';
import { prepareQuery } from 'Util/Query';
import { executePost } from 'Util/Request';
import EstimatedDateReusableBundle from './EstimatedDateReusableBundle.component';
import { connect } from 'react-redux';

/** @namespace Component/EstimatedDateReusableBundle/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    disclaimer: state.ConfigReducer.eta_disclaimer
})

/** @namespace Component/EstimatedDateReusableBundle/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({});

/** @namespace Component/EstimatedDateReusableBundle/Container */
export class EstimatedDateReusableBundleContainer extends DataContainer {
    static defaultProps = {
        skus: []
    }

    state = {
        dateInStock: 'Loading',
        status: false,
        isLoading: true,
        isShown: true,
        showDisclaimer: false
    }

    componentDidMount() {
        const {
            skus,
            qty,
            isSimple
        } = this.props;

        this.getDatesInStock(skus, qty);

        if (isSimple) {
            this.setState(state => ({ ...state, isShown: false }));
        }
    }

    componentDidUpdate(prevProps) {
        const {
            skus,
            qty
        } = this.props;

        const {
            skus: prevSkus,
            qty: prevQty
        } = prevProps;

        if ((Array.isArray(skus) && Array.isArray(prevSkus) && !(skus.every(sku => prevSkus.includes(sku) && skus.length == prevSkus.length))) || qty !== prevQty) {
            this.getDatesInStock(skus, qty);
        }
    }

    componentWillUnmount() {
        if (this.promise) {
            this.promise.cancel();
        }
    }

    getDatesInStock(skus, qty = 1) {
        this.setState({
            dateInStock: 'Loading',
            isLoading: true
        })
        if (this.promise) {
            this.promise.cancel();
        }
        this.fetchData(DatesInStockQuery.getQuery({ skus: skus.map(sku => ({ sku, qty })) }), this.onSuccess, this.onError);
    }

    onSuccess = (response) => {
        const { datesInStock } = response;

        const initial = new Date(0);

        const dateInStock = datesInStock.reduce((acc, val) => {
            const { date: dateVal } = val;

            if (!dateVal || !acc) {
                return null;
            }

            const dateValInt = parseInt(dateVal);

            if (isNaN(dateValInt) || isNaN(acc)) {
                return NaN;
            }

            const date = new Date(dateValInt);

            if (date > acc) {
                return date;
            }

            return acc;
        }, initial);

        const { isSimple } = this.props;

        if (isNaN(dateInStock)) {
            this.setState({
                dateInStock: 'Unknown',
                status: false,
                isLoading: false,
                isShown: false,
                showDisclaimer: false
            })
            return
        }

        if (dateInStock === null) {
            this.setState({
                dateInStock: 'Not in stock',
                status: true,
                isLoading: false,
                isShown: true,
                showDisclaimer: false
            })
            return;
        }

        const now = new Date();

        if (dateInStock - (1000 * 60 * 2) < now) {
            this.setState({
                dateInStock: 'In stock',
                status: true,
                isLoading: false,
                isShown: false,
                showDisclaimer: true
            })
            return;
        }

        this.setState({
            dateInStock: isSimple ? __('Out of stock back on %s', dateInStock.toLocaleDateString('en-GB')) : `Expected in stock on ${dateInStock.toLocaleDateString('en-GB')}`,
            status: true,
            isLoading: false,
            isShown: true,
            showDisclaimer: false
        })
    }

    onError = (error) => {
        this.setState({
            dateInStock: 'Failed to load stock',
            status: false,
            isLoading: false,
            isShown: false,
            showDisclaimer: false
        })
        console.log(error);
    }

    fetchData(rawQueries, onSuccess = () => {}, onError = () => {}) {
        const preparedQuery = prepareQuery(rawQueries);

        this.promise = makeCancelable(
            executePost(preparedQuery)
        );

        this.promise.promise.then(
            (response) => onSuccess(response),
            (err) => onError(err)
        );
    }

    render() {
        return <EstimatedDateReusableBundle { ...this.state } { ...this.props } />
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EstimatedDateReusableBundleContainer);