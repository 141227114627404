// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import ShippingOptionsComponent from './ShippingOptions.component';

const ShippingOptionsDispatcher = import(
    '../../store/ShippingOptions/ShippingOptions.dispatcher'
);

export const mapStateToProps = (state) => ({
    // wishlistItems: state.WishlistReducer.productsInWishlist
    options: state.ShippingOptionsReducer.options
});

export const mapDispatchToProps = (_dispatch) => ({
    // addProduct: options => CartDispatcher.addProductToCart(dispatch, options)
    getShippingOptions: () => ShippingOptionsDispatcher.then(
        ({ default: dispatcher }) => dispatcher.getShippingOptions(_dispatch)
    )
});

export class ShippingOptionsContainer extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    state = {
        checkboxsStatus: []
    }

    componentDidMount() {
        const { getShippingOptions } = this.props;

        getShippingOptions();
    }

    containerFunctions = {
        // getData: this.getData.bind(this)
        onCheckboxClick: this.onCheckboxClick.bind(this),
        prepareSelectOptionData: this.prepareSelectOptionData.bind(this)
    };

    containerProps() {
        // isDisabled: this._getIsDisabled()
    }

    onCheckboxClick(e, index) {
        const { checkboxsStatus } = this.state;
        var newCheckboxsStatus = checkboxsStatus;

        if (newCheckboxsStatus.length == 0) {
            newCheckboxsStatus[index] = {
                checked: true
            }
            this.setState({ checkboxsStatus: newCheckboxsStatus });
            return;
        }

        newCheckboxsStatus[index] = {
            checked: true
        }

        newCheckboxsStatus.forEach((status, i) => {
            if (i != index) {
                status.checked = false;
            }
        });

        this.setState({ checkboxsStatus: newCheckboxsStatus });
    }

    prepareSelectOptionData() {
        const { options = [] } = this.props;
        const selectOptions = [];

        if (options.length == 0) {
            selectOptions.push({
                id: 0,
                value: '',
                disable: false,
                label: __('Missing Shipping Options')
            });
        } else {
            options.forEach((option, index) => {
                selectOptions.push({
                    id: index,
                    value: option.title,
                    disable: false,
                    label: option.title
                });
            });
        }

        return selectOptions;
    }

    render() {
        return (
            <ShippingOptionsComponent
                {...this.state}
                {...this.props}
                {...this.containerFunctions}
                {...this.containerProps()}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ShippingOptionsContainer);
