/* eslint-disable */
// import MyAccountProductBacklog from 'Component/MyAccountProductBacklog';
import MyAccountProfile from 'Component/MyAccountProfile';
import {
    PROFILE
} from 'Route/MyAccount/MyAccount.config';

const addToTab = (member, instance) => {
    return ({
        [PROFILE]: {
            url: '/profile',
            name: __('Profile')
        },
        // [PRODUCT_BACKLOG]: {
        //     url: '/product-backlog',
        //     name: __('Product Backlog')
        // },
        ...member,
    });
}

const addToRenderMap = (member, instance) => {
    return({
        ...member,
        // [PRODUCT_BACKLOG]: MyAccountProductBacklog,
        [PROFILE]: MyAccountProfile
    });
}

export default {
    'Route/MyAccount/Container': {
        'member-property': {
            'tabMap': {
                implementation: addToTab,
                position: 1
            }
        }
    },
    'Route/MyAccount/Component': {
        'member-property': {
            'renderMap': {
                implementation: addToRenderMap,
                position: 1
            }
        }
    }
}