/* eslint-disable */
// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { ETA_POPUP_ID } from './MyAccountProductBacklogPopup.config';

import MyAccountProductBacklogPopup from './MyAccountProductBacklogPopup.component';

export const mapStateToProps = (state) => ({
    payload: state.PopupReducer.popupPayload[ETA_POPUP_ID] || {},
    disclaimer: state.ConfigReducer.eta_disclaimer
});

export const mapDispatchToProps = (_dispatch) => ({
    // addProduct: options => CartDispatcher.addProductToCart(dispatch, options)
});

export class MyAccountProductBacklogPopupContainer extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    containerFunctions = {
        // getData: this.getData.bind(this)
    };

    containerProps() {
        // isDisabled: this._getIsDisabled()
    }

    render() {
        return (
            <MyAccountProductBacklogPopup
                { ...this.props }
                {...this.containerFunctions}
                {...this.containerProps()}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountProductBacklogPopupContainer);
