/* eslint-disable linebreak-style */
/* eslint-disable simple-import-sort/sort */
/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/* eslint-disable */
import { PureComponent } from 'react';
import { formatPrice } from 'Util/Price'
import Link from 'Component/Link';

import './MyAccountRecentOrders.style.scss';

/** @namespace Component/MyAccountRecentOrders/Component */
export class MyAccountRecentOrdersComponent extends PureComponent {
    renderRecentOrdersBlock() {
        // const items = ['1', '2', '3', '4'];
        const { orderList = [] } = this.props;

        if (orderList.length == 0) return;

        orderList.sort((a,b) => {
            const dateA = this.parseDate(a.created_at);
            const dateB = this.parseDate(b.created_at);
            if (dateA > dateB) {
                return 1;
            } else {
                return -1;
            }
        });
        
        return (
            <div block="MyAccountRecentOrders">
                <table cellPadding="0" cellSpacing="0" block="Table" mods={{ style_cards: true }}>
                    <thead>
                        <tr block="Table" elem="Header">
                            <th block="Table" elem="HeaderData" mix={{ block: 'MyAccountRecentOrders', elem: 'HeaderDataOrder' }}>{ __('Order#') }</th>
                            <th block="Table" elem="HeaderData" mods={{ align_left: true }}>{ __('Shipping Address') }</th>
                            <th block="Table" elem="HeaderData">{ __('Order Date') }</th>
                            <th block="Table" elem="HeaderData">{ __('Tracking ID') }</th>
                            <th block="Table" elem="HeaderData">{ __('Order / Pending') }</th>
                            <th block="Table" elem="HeaderData">{ __('Status') }</th>
                            <th block="Table" elem="HeaderData">{ __('Total') }</th>
                            <th block="Table" elem="HeaderData">{ __('View') }</th>
                        </tr>
                    </thead>
                    <tbody block="Table" elem="Body">
                        { orderList.map((order, index) => (this.renderRecentOrdersItem(order, index))) }
                    </tbody>
                </table>
            </div>
        );
    }

    renderRecentOrdersItem(order, index) {
        const { countries = [], currency_code = '', orderList = [] } = this.props
        const {
            base_order_info: {
                id = 0,
                increment_id = '',
                created_at = '',
                total_qty_ordered = 0,
                total_qty_shipped = 0,
                status_label = '',
                grand_total = 0,
            } = {},
            shipping_info: {
                tracking_numbers = [],
                shipping_address: {
                    street = '',
                    postcode = '',
                    city = '',
                    country_id = ''
                } = {}
            } = {}
        } = order;

        const country = countries.find(({id}) => id == country_id);
        if (country == undefined) return;
        const {label: countryLabel = ''} = country;
        
        if (index >= 4) return;

        return (
            <tr block="Table" elem="Item" key={ index }>
                <td block="Table" elem="ItemData" mods={{ color_primaryLight: true }} mix={{ block: 'MyAccountRecentOrders', elem: 'ItemDataOrder' }}>
                    <span block="Table" elem="Label">{ __('Order#') }</span>
                    #{increment_id}
                </td>
                <td block="Table" elem="ItemData" mods={{ type_address: true }}>
                    <span block="Table" elem="Label">{ __('Shipping Address') }</span>
                    <address block="MyAccountRecentOrders" elem="ItemAddress">
                        {street}
                        <br />
                        {postcode}
                        <br />
                        {city}
                        <br />
                        {countryLabel}
                    </address>
                </td>
                <td block="Table" elem="ItemData">
                    <span block="Table" elem="Label">{ __('Order Date') }</span>
                    {this.parseDate(created_at).toLocaleDateString('en-GB')}
                </td>
                <td block="Table" elem="ItemData" mods={{ color_primaryLight: true }} mix={{ block: 'MyAccountRecentOrders', elem: 'ItemDataTrackingId' }}>
                {tracking_numbers.map(tracking_number => {
                    return (
                        <>
                            <span block="Table" elem="Label">{ __('Tracking ID') }</span>
                            {tracking_number}
                        </>
                    );
                })} 
                </td>
                <td block="Table" elem="ItemData">
                    <span block="Table" elem="Label">{ __('Order / Pending') }</span>
                    {total_qty_ordered} /
                    <span block="MyAccountRecentOrders" elem="TableOrderPending"> {total_qty_ordered - total_qty_shipped}</span>
                </td>
                <td block="Table" elem="ItemData" mods={{ color_info: true }}>
                    <span block="Table" elem="Label">{ __('Status') }</span>
                    { status_label }
                </td>
                <td block="Table" elem="ItemData">
                    <span block="Table" elem="Label">{ __('Total') }</span>
                    {formatPrice(grand_total, currency_code)}
                </td>
                <td block="Table" elem="ItemData" mods={{ type_hasMobileButton: true }}>
                    <span block="Table" elem="Label">{ __('View') }</span>
                    <Link to={`/my-account/my-orders/${id}`} block="Table" elem="Button" mix={{ block: 'Button' }}>{ __('View') }</Link>
                </td>
            </tr>
        );
    }

    parseDate(date) {
        if (date == null) return null
        const dateArr = date.split(/[^0-9]/);
        const parsedDate = new Date(dateArr[2], dateArr[1] - 1, dateArr[0])
        return parsedDate;
    }

    render() {
        return (
            <>
                { this.renderRecentOrdersBlock() }
            </>
        );
    }
}

export default MyAccountRecentOrdersComponent;
