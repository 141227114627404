/* eslint-disable linebreak-style */
/* eslint-disable simple-import-sort/sort */
/* eslint-disable react/jsx-curly-spacing */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/derived-class-names */
/* eslint-disable @scandipwa/scandipwa-guidelines/use-namespace */
/* eslint-disable */
import { PureComponent } from 'react';

import Link from 'Component/Link';
import Loader from 'Component/Loader';
import MyAccountProductBacklogPopup from '@hoeks-b2b/product-backlog/src/component/MyAccountProductBacklogPopup';

import './MyAccountProductBackorders.style.scss';

/** @namespace Component/MyAccountProductBackorders/Component */
export class MyAccountProductBackordersComponent extends PureComponent {
    handleShowPopup = (e, sku) => {
        const { showPopup, productList = [] } = this.props;
        e.preventDefault();
        const product = productList.filter(product => product.sku === sku)[0];
        showPopup(product);
    };

    renderPopup() {
        return (
            <MyAccountProductBacklogPopup />
        );
    }

    renderProductBackordersBlock() {
        const { productList = [], getNextDeliveryDate } = this.props;
        const sortedProductList = this.sortProductsAfterNextDelivery(productList);

        if (sortedProductList[0] == undefined) {
            return (
                <h4 block="MyAccountProductBackorders" elem="TextNoBacklog">{__('No products in backlog')}</h4>
            );
        }

        return (
            <div block="MyAccountProductBackorders" onClick={(event) => this.handleShowPopup(event, sortedProductList[0].sku)}>
                <h2 block="MyAccountProductBackorders" elem="Date">{getNextDeliveryDate(sortedProductList[0].nextDeliveryDate) ?? ''.replace(/\//g, '.')}</h2>
                <h4 block="MyAccountProductBackorders" elem="Text">{__('Next Expected Delivery')}</h4>
            </div>
        );
    }

    sortProductsAfterNextDelivery(productList = []) {
        return productList.sort((a, b) => {
            if (a.nextDeliveryDate == null) {
                return 1;
            }

            if (b.nextDeliveryDate == null) {
                return -1;
            }
            return new Date(a.nextDeliveryDate) - new Date(b.nextDeliveryDate);
        })
    }

    renderProductBackordersItem(product, index) {
        const { getNextDeliveryDate, getNumberOfBackorders } = this.props;

        return (
            <>
            { index <= 5 ? <tr block="Table" elem="Item" key={index}>
                <td block="Table" elem="ItemData" mods={{ align_left: true, type: 'products' }}>
                    <span block="Table" elem="Label">{ __('Product') }</span>
                    <Link to={product.productUrl} block="Table" elem="ItemData" mods={{ color_primaryLight: true }}>{ product.productName }</Link>
                </td>
                <td block="Table" elem="ItemData" mods={{ type: 'nextDelivery' }}>
                    <span block="Table" elem="Label">{ __('Next delivery') }</span>
                    { getNextDeliveryDate(product.orders) }
                </td>
                <td block="Table" elem="ItemData" mods={{ type: 'backOrders' }}>
                    <span block="Table" elem="Label">{ __('Back orders #') }</span>
                    { getNumberOfBackorders(product.orders) }
                </td>
                <td block="Table" elem="ItemData" mods={{ type_hasMobileButton: true, type: 'openOrders' }}>
                    <span block="Table" elem="Label">{ __('Open orders') }</span>
                    <button block="Table" elem="Link" onClick={(event) => this.handleShowPopup(event, product.sku)}>
                        <span block="Table" elem="Helper" mods={{ isHiddenBeforeDesktop: true }}>{ __('View All') }</span>
                        <span block="Table" elem="Helper" mods={{ isHiddenOnDesktop: true }}>{ __('View All Open Orders') }</span>
                    </button>
                </td>
            </tr> : <></>}
            </>
        );
    }

    render() {
        const { isLoading } = this.props;
        return (
            <>
                { isLoading ? 
                    <Loader isLoading={isLoading} /> : 
                    <>
                        { this.renderProductBackordersBlock() }
                        { this.renderPopup() }
                    </> 
                }
            </>
        );
    }
}

export default MyAccountProductBackordersComponent;
