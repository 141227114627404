/* eslint-disable */
import { LinkContainer as SourceLinkContainer, mapStateToProps, mapDispatchToProps } from 'SourceComponent/Link/Link.container';
import { connect } from 'react-redux';

import { appendWithStoreCode } from 'Util/Url';

export class LinkContainer extends SourceLinkContainer {
    getTo() {
        const { to = {} } = this.props;

        if (to == null) {
            return appendWithStoreCode('/');
        }

        if (typeof to === 'string') {
            // in case this URL is absolute, do not append store
            if (/^https?:\/\//.test(to)) {
                return to;
            }

            return appendWithStoreCode(to);
        }

        const { pathname = '/' } = to;

        return {
            pathname: appendWithStoreCode(pathname),
            ...to
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LinkContainer)