/* eslint-disable */
import { Field } from 'Util/Query';

/**
 * DatesInStock Query
 * @class DatesInStockQuery
 * @namespace Query/DatesInStock
 */
export class DatesInStockQuery {
    __construct() {
        super.__construct();
        this.options = {};
    }

    getQuery(options = {}) {
        this.options = options;

        return new Field('datesInStock')
            .addArgument(...this._getConditionalArguments())
            .addFieldList(this._getDefaultFields());
    }

    _getConditionalArguments() {
        const { skus } = this.options;

        if (skus) {
            return ['skus', '[DateInStockRequest]', skus];
        }

        throw new Error(__('There was an error requesting the back in stock dates'));
    }

    _getDefaultFields() {
        return [
            'sku',
            'date'
        ];
    }
}

export default new DatesInStockQuery();