/* eslint-disable */
import CartQuery from 'Query/Cart.query';
import { fetchMutation } from 'Util/Request';
import { showNotification } from 'Store/Notification/Notification.action';
import { getExtensionAttributes } from 'Util/Product';
import { isSignedIn } from 'Util/Auth';

const getSaveCartItemMutation = (args, callback, instance) => {
    const [product, quoteId] = args;
    // product.estimated_date = 'Test date';
    return callback.apply(instance, [product, quoteId]);
}

const addSimpleProductToCart = (args, callback, instance) => {
    const {
        product,
        quantity,
        addProduct,
        productOptionsData,
        estimatedDate
    } = instance.props;

    addProduct({
        product,
        quantity,
        productOptionsData,
        estimatedDate
    }).then(
        /** @namespace Component/AddToCart/Container/addSimpleProductToCartAddProductThen */
        () => instance.afterAddToCart(),
        /** @namespace Component/AddToCart/Container/addSimpleProductToCartAddProductCatch */
        () => instance.resetLoading()
    );
    // return callback.apply(instance, args);
}

const addProductToCart = async (args, callback, instance) => {
    const [dispatch, options] = args;

    const guestQuoteId = instance._getGuestQuoteId();
    const {
        product,
        quantity,
        productOptionsData,
        estimatedDate
    } = options;

    const {
        sku,
        type_id: product_type
    } = product;

    const {
        productOptions,
        productOptionsMulti
    } = productOptionsData || {};

    const productToAdd = {
        sku,
        product_type,
        quantity,
        estimated_date: estimatedDate,
        product_option: {
            extension_attributes: getExtensionAttributes(
                {
                    ...product,
                    productOptions,
                    productOptionsMulti
                }
            )
        }
    };

    if (!guestQuoteId) {
        await instance.createGuestEmptyCart(dispatch);
    }

    if (instance._canBeAdded(options)) {
        try {
            const { saveCartItem: { cartData } } = await fetchMutation(CartQuery.getSaveCartItemMutation(
                productToAdd, !isSignedIn() && instance._getGuestQuoteId()
            ));

            return instance._updateCartData(cartData, dispatch);
        } catch ([{ message }]) {
            dispatch(showNotification('error', message));
            return Promise.reject();
        }
    }

    return Promise.reject();
}

export default {
    'Query/Cart': {
        'member-function': {
            getSaveCartItemMutation
        }
    },
    'Component/AddToCart/Container': {
        'member-function': {
            addSimpleProductToCart
        }
    },
    'Store/Cart/Dispatcher': {
        'member-function': {
            addProductToCart
        }
    }
}