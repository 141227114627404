// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Field from 'Component/Field';
import { CHECKBOX_TYPE, TEXT_TYPE, SELECT_TYPE } from 'Component/Field/Field.config';
import FormPortal from 'Component/FormPortal';
import { SHIPPING_STEP } from 'Route/Checkout/Checkout.config';

import './ShippingOptions.style';

/** @namespace Hoeks/ShippingOptions/Component/ShippingOptions/Component/ShippingOptionsComponent */
export class ShippingOptionsComponent extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    render() {
        // console.log('test',this.props);
        const { options, onCheckboxClick, checkboxsStatus, prepareSelectOptionData } = this.props;
        return (
            <div block="ShippingOptions">
                { /* TODO: Implement render method */}
                {/* <Field id="test" name="test" type={TEXT_TYPE} /> */}
                <FormPortal id={SHIPPING_STEP} name={'shipping_options_form'}>
                    <h2 block="Checkout" elem="Heading">{__('Select Shipping Option')}</h2>
                    <Field 
                        id='Shipping-Option'
                        name='Shipping-Option'
                        type={SELECT_TYPE}
                        selectOptions={prepareSelectOptionData()}
                        fromBikeForm={true}
                        // onChange={(value) => console.log('debug', value)}
                    />
                </FormPortal>
            </div>
        );
    }
}

export default ShippingOptionsComponent;
