/* eslint-disable */
import { PureComponent } from 'react';
import Link from 'Component/Link';
// import MyAccountProductBacklogPopup from '../MyAccountProductBacklogPopup';
import MyAccountProductBacklogPopup from '../MyAccountProductBacklogPopup'
import './MyAccountProductBacklog.style.scss';
import Image from 'Component/Image';
import Field from 'Component/Field';
import {TEXT_TYPE, SELECT_TYPE} from 'Component/Field/Field.config';

/** @namespace Component/MyAccountProductBacklog/Component */
export class MyAccountProductBacklog extends PureComponent {
    renderSearchFilter() {
        const {
            onTextFieldChange,
            onSelectChange,
            onApplyFilterClick,
            product_name,
            sku,
            order_number,
            order_notes,
            order_status
        } = this.props;

        return (
            <div block="MyAccountMyOrders" elem="SearchFilter">
                <div block="MyAccountMyOrders" elem="SearchFilterHeadline">{__('Search / Filter')}</div>
                <div block="Form" elem="Row">
                    <div block="Form" elem="InputGroup">
                        <label block="Field" elem="Label">
                            {__('Product Name')}
                        </label>
                        <Field
                            id="ProductName"
                            name="ProductName"
                            type={TEXT_TYPE}
                            block="Form"
                            elem="Input"
                            mix={{ block: 'MyAccountMyOrders', elem: 'Field' }}
                            onChange={(value) => onTextFieldChange(value, 'product_name')}
                            value={product_name}
                        />
                    </div>
                    <div block="Form" elem="InputGroup">
                        <label block="Field" elem="Label">
                            {__('SKU')}
                        </label>
                        <Field
                            id="Sku"
                            name="Sku"
                            type={TEXT_TYPE}
                            block="Form"
                            elem="Input"
                            onChange={(value) => onTextFieldChange(value, 'sku')}
                            value={sku}
                        />
                    </div>
                </div>
                <div block="Form" elem="Row">
                    <div block="Form" elem="InputGroup">
                        <label block="Field" elem="Label">
                            {__('Order Notes (customer name, internal, order no., etc.).')}
                        </label>
                        <Field
                            id="OrderNotes"
                            name="OrderNotes"
                            type={TEXT_TYPE}
                            block="Form"
                            elem="Input"
                            onChange={(value) => onTextFieldChange(value, 'order_notes')}
                            value={order_notes}
                        />
                    </div>
                    <div block="Form" elem="InputGroup" mods={{ align_rightBeforeDesktop: true }}>
                        <button block="Button" mods={{ isHollow: true }} onClick={onApplyFilterClick} mix={{ block: 'Form', elem: 'Button' }}>{__('Apply Filter')}</button>
                    </div>
                </div>
            </div>
        );
    }

    renderTableHeader() {
        return (
            <thead block="MyAccountProductBacklog" elem="TableHeader">
                <tr block="Table" elem="Header">
                    <th block="Table" elem="HeaderData" mods={{ type_thumbnail: true }}></th>
                    <th block="Table" elem="HeaderData" mix={{ block: 'MyAccountProductBacklog', elem: 'HeaderDataSku' }}>{__('SKU')}</th>
                    <th block="Table" elem="HeaderData" mods={{ align_left: true }}>{__('Name')}</th>
                    <th block="Table" elem="HeaderData">{__('Next Delivery')}</th>
                    <th block="Table" elem="HeaderData">{__('All ETAs')}</th>
                    <th block="Table" elem="HeaderData">{__('#Back Orders')}</th>
                    <th block="Table" elem="HeaderData">{__('Shop')}</th>
                </tr>
            </thead>
        );
    }

    renderPopup() {
        return (
            <MyAccountProductBacklogPopup />
        );
    }

    handleShowPopup = (e, sku) => {
        const { showPopup, productList = [] } = this.props;
        e.preventDefault();
        const product = productList.filter(product => product.sku === sku)[0];
        showPopup(product);
    };

    renderNextDelivery(nextDeliveryDate) {
        const { parseDate, disclaimer, showWithin, showWithinText } = this.props;
        const now = new Date();
        const date = parseDate(nextDeliveryDate)
        if ((date - (1000 * 60 * 2)) < now) {
            return <>{ __('In stock') }<br/>{ disclaimer }</>
        }

        if (+showWithin) {
            const showWithinDate = new Date();
            showWithinDate.setDate(now.getDate() + (+showWithin));

            if (date > showWithinDate) {
                return showWithinText ?? __('Awaiting ETA');
            }
        }

        return nextDeliveryDate == null ? showWithinText ?? __('Awaiting ETA') : parseDate(nextDeliveryDate).toLocaleDateString('en-GB')
    }

    renderBackOrders(orders = []) {
        var backOrders = 0;
        orders.forEach(({ eta, itemQty }) => {
            backOrders = backOrders + parseInt(itemQty);
        });
        return backOrders;
    }

    renderTableItem(product) {
        const {
            selectedOptions = []
        } = product;

        return (
            <>
            <tr block="Table" elem="Item">
                <td block="Table" elem="ItemData" mods={{ type_thumbnail: true }}>
                    {/* <img src={`/media${product.image}`} /> */}
                    <Image
                        src={product.image}
                        alt={product.productName}
                        mix={{ block: 'MyAccountProductBacklog', elem: 'ItemDataThumbnail' }}
                    />
                </td>
                <td block="Table" elem="ItemData" mix={{ block: 'MyAccountProductBacklog', elem: 'ItemDataSku'}}>
                    <span block="Table" elem="Label">{__('SKU')}</span>
                    {product.sku}
                </td>
                <td block="Table" elem="ItemData" mods={{ align_left: true }}>
                    <span block="Table" elem="Label">{__('Name')}</span>
                    {product.productName}
                    { selectedOptions.map(({ label, value = [] }) => {
                            return (
                                <span block="Table" elem="Option">
                                    <br />
                                    <span block="Table" elem="OptionLabel">{ label }</span>: <span block="Table" elem="OptionValue">{ value.join(', ') }</span>
                                </span>
                            )
                        }) }
                </td>
                <td block="Table" elem="ItemData">
                    <span block="Table" elem="Label">{__('Next Delivery')}</span>
                    {this.renderNextDelivery(product.nextDeliveryDate)}
                </td>
                <td block="Table" elem="ItemData" mods={{ type_hasMobileButton: true }}>
                    <span block="Table" elem="Label">{__('All ETAs')}</span>
                    <button onClick={(event) => this.handleShowPopup(event, product.sku)} block="Button" mods={{ isHollow: true }} mix={{ block: 'Table', elem: 'Button' }}>{__('View All ETAs')}</button>
                </td>
                <td block="Table" elem="ItemData" mods={{ color_primaryLight: true }}>
                    <span block="Table" elem="Label">{__('#Back Orders')}</span>
                    {this.renderBackOrders(product.orders)}
                </td>
                <td block="Table" elem="ItemData" mods={{ type_hasMobileButton: true }}>
                    <span block="Table" elem="Label">{__('Shop')}</span>
                    <Link to={`/${product.productUrl}`} block="Button" mix={{ block: 'Table', elem: 'Button' }}>{__('Shop')}</Link>
                </td>
            </tr>
                {
                    product.childItems.length ? 
                        product.childItems.map(item => this.renderChildItem(item, product.sku))
                    :
                    <></>
                }
            </>
        );
    }

    renderChildItem(item, parentSku) {
        return (
            <tr block="Table" elem="Item" mods={{ type: 'child' }}>
                <td block="Table" elem="ItemData" mods={{ type: "thumbnail", align: 'right' }}>
                    <div block="Table" elem="ChildArrow">&#x2934;</div>
                    <Image
                        src={item.image}
                        alt={item.productName}
                        mix={{ block: 'MyAccountProductBacklog', elem: 'ItemDataThumbnail' }}
                    />
                </td>
                <td block="Table" elem="ItemData">
                    <span block="Table" elem="Label">{__('SKU')}</span>
                    {item.sku}
                </td>
                <td block="Table" elem="ItemData" mods={{ align: "left" }}>
                    <span block="Table" elem="Label">{__('Name')}</span>
                    {item.productName}
                </td>
                <td block="Table" elem="ItemData" mods={{ align: "left" }} colSpan="4">
                    <span block="Table" elem="Label">{__('Child item of ')}</span>
                    <span block="Table" elem="Helper" mods={{ isHiddenBeforeDesktop: true }}>{__('Child item of ')}</span><span>{parentSku}</span>
                </td>
                {/* <td block="Table" elem="ItemData" mods={{ type_hasMobileButton: true }}></td>
                <td block="Table" elem="ItemData" mods={{ color_primaryLight: true }}></td>
                <td block="Table" elem="ItemData" mods={{ type_hasMobileButton: true }}></td> */}
            </tr>
        )
    }

    renderTableContent() {
        const { filteredProductList } = this.props;
        
        return (
            <tbody block="MyAccountProductBacklog" elem="TableBody">
                {filteredProductList.map(product => this.renderTableItem(product))}
            </tbody>
        );
    }

    renderTable() {
        return (
            <table block="Table" mods={{ style_cards: true }} mix={{ block: 'MyAccountProductBacklog', elem: 'Table' }}>
                {this.renderTableHeader()}
                {this.renderTableContent()}
            </table>
        );
    }

    renderContents() {
        return (
            <div block="MyAccountProductBacklog" elem="Content">
                {this.renderTable()}
            </div>
        );
    }

    render() {
        return (
            <div block="MyAccountProductBacklog">
                {this.renderSearchFilter()}
                {this.renderContents()}
                {this.renderPopup()}
            </div>
        );
    }
}

export default MyAccountProductBacklog;