// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import BikeFormComponent from './BikeForm.component';

import NewsletterSubscriptionQuery from 'Query/NewsletterSubscription.query';
import { debounce, fetchMutation, fetchQuery } from 'Util/Request';

import {
    TERMS_AND_CONDITIONS_POPUP_ID
} from 'Component/CheckoutTermsAndConditionsPopup/CheckoutTermsAndConditionsPopup.config';
import { showNotification } from 'Store/Notification/Notification.action';
import { showPopup } from 'Store/Popup/Popup.action';

export const BikeFormDispatcher = import(
    '../../store/BikeForm/BikeForm.dispatcher'
);

export const NewsletterSubscriptionDispatcher = import(
    'SourceStore/NewsletterSubscription/NewsletterSubscription.dispatcher'
);

export const mapDispatchToProps = (dispatch) => ({
    showErrorNotification: (message) => dispatch(showNotification('error', message)),
    showSuccessNotification: (message) => dispatch(showNotification('success', message)),
    showPopup: (payload) => dispatch(showPopup(TERMS_AND_CONDITIONS_POPUP_ID, payload)),
    subscribeToBike: (data) => BikeFormDispatcher.then(
        ({default: dispatcher}) => dispatcher.subscribeToBike(dispatch, data)
    ),
    updateBikeFormStatus: (status) => BikeFormDispatcher.then(
        ({default: dispatcher}) => dispatcher.updateBikeFormStatus(dispatch, status)
    ),
    subscribeToNewsletter: (email => NewsletterSubscriptionDispatcher.then(
        ({default: dispatcher}) => dispatcher.subscribeToNewsletter(dispatch, email)
    ))
});

export const mapStateToProps = (state) => ({
    termsAreEnabled: state.ConfigReducer.terms_are_enabled,
    termsAndConditions: state.ConfigReducer.checkoutAgreements,
    bikeFormStatus: state.BikeFormReducer.status
});

/** @namespace Hoeks/UpcomingBike-Form/Component/BikeForm/Container/BikeFormContainer */
export class BikeFormContainer extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    state = {
        isTACAccepted: false,
        isIANRAccepted: false,
        isNewsletterAccepted: false
    }

    containerFunctions = {
        // getData: this.getData.bind(this)
        handleShowPopup: this.handleShowPopup.bind(this),
        onFormSucess: this.onFormSucess.bind(this),
        onFormError: this.onFormError.bind(this),
        setTACAccepted: this.setTACAccepted.bind(this),
        setIANRAccepted: this.setIANRAccepted.bind(this),
        setNewsletterAccepted: this.setNewsletterAccepted.bind(this)
    };

    containerProps() {
        // isDisabled: this._getIsDisabled()
    }

    componentDidMount() {
        // console.log(this.props);
    }

    componentWillUnmount() {
        const { updateBikeFormStatus } = this.props;
        updateBikeFormStatus(null);
    }

    handleShowPopup(e) {
        const { showPopup, termsAndConditions } = this.props;
        const {
            name: title = __('Terms and Conditions'),
            content: text = __('There are no Terms and Conditions configured')
        } = termsAndConditions[0] || {};

        e.preventDefault();
        return showPopup({
            title, text
        });
    }

    showPopup() {
        const { showPopup, termsAndConditions } = this.props;
        const {
            name: title = __('Terms and Conditions'),
            content: text = __('There are no Terms and Conditions configured')
        } = termsAndConditions[0] || {};

        return showPopup({
            title, text
        });
    }

    // async subscribeToNewsletter(email) {
    //     const { showSuccessNotification, showErrorNotification } = this.props;
    //     const mutation = NewsletterSubscriptionQuery.getSubscribeToNewsletterMutation(email);
    //     var subscriptionStatus;
    //     return fetchMutation(mutation).then(result => {
    //             console.log(result);
    //             subscriptionStatus = result;
    //             if ( result.subscribeEmailToNewsletter == undefined) return;
    //             const { status } = result.subscribeEmailToNewsletter;
    //             if (status == "SUBSCRIBED") {
    //                 showSuccessNotification(__('A subscription confirmation email has been sent!'));
    //             }
    //             return result;
    //         },
    //         (error) => { showErrorNotification(error[0].message) }
    //     )
    // }

    onFormSucess(fields) {
        const { subscribeToBike, showErrorNotification, subscribeToNewsletter } = this.props;
        const { isNewsletterAccepted } = this.state;
        const {
            company_namewarding = '',
            namewarding,
            emailwarding,
            countrywarding,
            use_it_forwarding,
            interested_inwarding,
        } = fields;

        if (this.checkHoneyPotTrap(fields)) return;
        // console.log(isNewsletterAccepted)
        const data = {
            company_name: company_namewarding,
            name: namewarding,
            email: emailwarding,
            country: countrywarding,
            use_it_for: use_it_forwarding,
            interested_in: interested_inwarding,
            subscribed_to_newsletter: isNewsletterAccepted
        }
        
        if (data.use_it_for == "none selected" || data.interested_in == "none selected") {
            showErrorNotification(__('Missing vaules in one or more, of the following fields (Name, Email, Country, "We are planning to use it for:", "I am interested in:")'));
            return;
        }

        if (isNewsletterAccepted) {
            subscribeToNewsletter(data.email).then(result => {
                // console.log(result);
                if (result == undefined) {
                    subscribeToBike(data);
                    return
                }
                const { msgType = '' } = result;
                if (msgType != 'error') {
                    subscribeToBike(data);   
                    return;      
                }
            });
            return;
        } else {
            data.subscribed_to_newsletter = "0";
            subscribeToBike(data);
        }
    }

    onFormError(fields) {
        const { showErrorNotification } = this.props;
        showErrorNotification(__('Missing vaules in one or more, of the following fields (Name, Email, Country, "We are planning to use it for:", "I am interested in:")'));
    }

    checkHoneyPotTrap(fields) {
        if(fields.name != undefined || fields.email != undefined || fields.country != undefined && fields.company_name) {
            return true;
        }
        return false;
    }

    setTACAccepted() {
        this.setState(({isTACAccepted: oldIsTACAccepted}) => ({
            isTACAccepted: !oldIsTACAccepted
        }));
    }

    setIANRAccepted() {
        this.setState(({isIANRAccepted: oldIsIANRAccepted}) => ({
            isIANRAccepted: !oldIsIANRAccepted
        }));
    }

    setNewsletterAccepted() {
        this.setState(({isNewsletterAccepted: oldIsNewsletterAccepted}) => ({
            isNewsletterAccepted: !oldIsNewsletterAccepted
        }));
    }

    render() {
        return (
            <BikeFormComponent
                {...this.props}
                {...this.state}
                {...this.containerFunctions}
                {...this.containerProps()}
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BikeFormContainer);
