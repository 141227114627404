/* eslint-disable */
import { FieldInput as SourceFieldInput } from 'SourceComponent/FieldInput/FieldInput.component';

export class FieldInput extends SourceFieldInput {
    render() {
        const {
            formRef,
            disabled,
            ...validProps
        } = this.props;
        // console.log('debug',validProps)
        if (disabled) {
            return (
                <input
                    ref={ formRef }
                    disabled
                    { ...validProps }
                />
            )
        }

        return (
            <input
              ref={ formRef }
              { ...validProps }
              readOnly={false}
            />
        );
    }
}

export default FieldInput;