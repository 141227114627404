/* eslint-disable */
import PropTypes from 'prop-types';
import { MyAccountDashboardContainer, mapStateToProps } from 'SourceComponent/MyAccountDashboard/MyAccountDashboard.container';
import { connect } from 'react-redux';
import MyAccountProfile from './MyAccountProfile.component';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace Component/MyAccountProfile/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    logout: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.logout(null, dispatch)
    )
});

/** @namespace Component/MyAccountProfile/Container */
export class MyAccountProfileContainer extends MyAccountDashboardContainer {
    static propTypes = {
        onSignOut: PropTypes.func,
        logout: PropTypes.func.isRequired
    };

    static defaultProps = {
        onSignOut: () => {}
    };

    containerFunctions = {
        getDefaultAddress: this.getDefaultAddress.bind(this),
        handleLogout: this.handleLogout.bind(this)
    };

    handleLogout() {
        const { onSignOut, logout } = this.props;
        /* test server */
        BrowserDatabase.deleteItem({prefix: 'da_partner', path: 'auth_token'});
        BrowserDatabase.deleteItem({prefix: 'da_partner', path: 'customer'});
        BrowserDatabase.deleteItem({prefix: 'en_partner', path: 'auth_token'});
        BrowserDatabase.deleteItem({prefix: 'en_partner', path: 'customer'});
        /* live server */
        BrowserDatabase.deleteItem({prefix: 'dkk_b2b_da', path: 'auth_token'});
        BrowserDatabase.deleteItem({prefix: 'dkk_b2b_da', path: 'customer'});
        BrowserDatabase.deleteItem({prefix: 'eur_b2b_en', path: 'auth_token'});
        BrowserDatabase.deleteItem({prefix: 'eur_b2b_en', path: 'customer'});
        logout();
        onSignOut();
    }

    render() {
        return (
            <MyAccountProfile
              { ...this.props }
              { ...this.containerFunctions }
            />
        );
    }
}

// /** @namespace Component/MyAccountProfile/Container/mapStateToProps */
// // eslint-disable-next-line no-unused-vars
// export const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(MyAccountProfileContainer);