/* eslint-disable */
export * from 'SourceComponent/Form/Form.config';

import { formConfig as sourceFormConfig } from 'SourceComponent/Form/Form.config';

export const MAX_ADDRESS_LENGTH = 30;

/** @namespace Component/Form/Config/validateAddressLength */
const validateAddressLength = ({ value }) => value.length <= MAX_ADDRESS_LENGTH;

/** @namespace Component/Form/Config */
export const formConfig = () => ({
    ...sourceFormConfig(),
    addressLength: {
        validate: validateAddressLength,
        message: __('Address should be a maxmimum of %s characters.', MAX_ADDRESS_LENGTH)
    }
});

export default formConfig();