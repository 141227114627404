/* eslint-disable */
import Link from 'Component/Link';
import Loader from 'Component/Loader';
import MyAccountNews from 'Component/MyAccountNews';
import MyAccountProductBackorders from 'Component/MyAccountProductBackorders';
import MyAccountRecentOrders from 'Component/MyAccountRecentOrders';
import { MyAccountDashboard as SourceMyAccountDashboard } from 'SourceComponent/MyAccountDashboard/MyAccountDashboard.component';

import './MyAccountDashboard.override.style.scss';

/** @namespace Component/MyAccountDashboard/Component */
export class MyAccountDashboard extends SourceMyAccountDashboard {
    renderShopButton() {
        return (
            <Link to="/shop" block="Button" mix={{ block: 'MyAccountDashboard', elem: 'ShopButton' }}>
                <span block="MyAccountDashboard" elem="ShopButtonExtra" mods={{ isLeft: true }} />
                { __('Shop') }
                <span block="MyAccountDashboard" elem="ShopButtonExtra" mods={{ isRight: true }} />
            </Link>
        );
    }

    renderDashboard() {
        const { onShowAllNewslettersClick, showAllNesletters } = this.props;
        return (
            <>
                <div block="MyAccountDashboard" elem="News">
                    <h2 block="MyAccountDashboard" elem="NewsTitle">{ __('News from Larry & Harry') }</h2>
                    <div
                      block="Card"
                      mix={{ block: 'MyAccountDashboard', elem: 'NewsContent' }}
                      mods={ showAllNesletters ? { type_isExpanded: true } : undefined }
                    >
                        <MyAccountNews />
                        <div block="MyAccountDashboard" elem="ViewAll">
                            <Link to="https://us13.campaign-archive.com/home/?u=79c2279c9c6f08d7ed9be9031&id=e08964cf4a" block="MyAccountDashboard" elem="ViewAllLink">
                                {  __('View All Newsletters') }
                                <span
                                block="MyAccountDashboard"
                                elem="ViewAllIcon"
                                mods={ showAllNesletters ? { type_isExpanded: true } : undefined }
                                />
                            </Link>
                        </div>
                    </div>
                </div>
                <div block="MyAccountDashboard" elem="ProductBackorders">
                    <h2 block="MyAccountDashboard" elem="ProductBackordersTitle">{ __('Product Backorders') }</h2>
                    <div
                      block="Card"
                      mix={{ block: 'MyAccountDashboard', elem: 'ProductBackordersContent' }}
                    >
                        <MyAccountProductBackorders />
                        <div block="MyAccountDashboard" elem="ViewAll">
                            <Link to="/my-account/product-backlog" block="MyAccountDashboard" elem="ViewAllLink">
                                { __('View All') }
                                <span block="MyAccountDashboard" elem="ViewAllIcon" />
                            </Link>
                        </div>
                    </div>
                </div>
                { this.renderShopButton() }
                <div block="MyAccountDashboard" elem="RecentOrders">
                    <h2 block="MyAccountDashboard" elem="RecentOrdersTitle">
                        { __('Recent Orders') }
                        <Link to="/my-account/my-orders" block="MyAccountDashboard" elem="RecentOrdersTitleLink">{ __('View All') }</Link>
                    </h2>
                    <div block="MyAccountDashboard" elem="RecentOrdersContent">
                        <MyAccountRecentOrders />
                        <div block="MyAccountDashboard" elem="ViewAll" mods={{ for_recentOrders: true }}>
                            <Link to="/my-account/my-orders" block="MyAccountDashboard" elem="ViewAllLink">
                                { __('View All') }
                                <span block="MyAccountDashboard" elem="ViewAllIcon" />
                            </Link>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    renderActionButtons() {
        const { handleLogout } = this.props;

        return(
            <div block="MyAccountDashboard" elem="ActionButtons">
                <Link to="https://faqb2b.larryvsharry.com/" block="MyAccountDashboard" elem="ActionButton">
                    <i block="MyAccountDashboard" elem="ActionButtonImage" mods={{ type: 'support' }}></i>
                    <span block="MyAccountDashboard" elem="ActionButtonText">{ __('Support & Downloads') }</span>
                </Link>
                <Link to="my-account/profile" block="MyAccountDashboard" elem="ActionButton">
                    <i block="MyAccountDashboard" elem="ActionButtonImage" mods={{ type: 'profile' }}></i>
                    <span block="MyAccountDashboard" elem="ActionButtonText">{ __('Profile') }</span>
                </Link>
                <button type="button" onClick={handleLogout} block="MyAccountDashboard" elem="ActionButton">
                    <i block="MyAccountDashboard" elem="ActionButtonImage" mods={{ type: 'logout' }}></i>
                    <span block="MyAccountDashboard" elem="ActionButtonText">{ __('Logout') }</span>
                </button>
            </div>
        );
    }

    render() {
        const { customer } = this.props;
        const name = customer.middlename ? `${customer.firstname} ${customer.middlename} ${customer.lastname}` : `${customer.firstname} ${customer.lastname}`;
        return (
            <div block="MyAccountDashboard">
                { this.renderActionButtons() }
                <p block="MyAccountDashboard" elem="Welcome">{ __('Welcome') } - {name} </p>
                <Loader isLoading={ !Object.keys(customer).length } />
                { this.renderDashboard() }
                { this.renderCustomerPopup() }
            </div>
        );
    }
}

export default MyAccountDashboard;
