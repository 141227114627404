/* eslint-disable */
// import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import Popup from 'Component/Popup';
import { ETA_POPUP_ID } from './MyAccountProductBacklogPopup.config';
import Image from 'Component/Image';
import Link from 'Component/Link';

import './MyAccountProductBacklogPopup.style';

export class MyAccountProductBacklogPopup extends PureComponent {
    static propTypes = {
        // TODO: implement prop-types
    };

    renderProduct() {
        // console.log('debug', this.props)
        const {
            payload: {
                product: {
                    image,
                    productName
                } = {},
                product_image = '/'
            } = {}
        } = this.props;

        return (
            <div block="MyAccountProductBacklogPopup" elem="ProductInfo">
                <Image mix={{ block: 'MyAccountProductBacklogPopup', elem: 'ProductInfoImage' }} src={image ? image : product_image} alt={productName} />
                <h1 block="MyAccountProductBacklogPopup" elem="ProductInfoTitle">{productName} {__('Backlog')}</h1>
            </div>
        );
    }

    renderHeadline() {
        return (
            <div block="MyAccountProductBacklogPopup" elem="Headline">
                <h2 block="MyAccountProductBacklogPopup" elem="HeadlineTitle">{__('Recent Orders and ETA')}</h2>
            </div>
        );
    }

    renderTableHeader() {
        return (
            <thead>
                <tr block="Table" elem="Header">
                    <th block="Table" elem="HeaderData">{__('Order Date')}</th>
                    <th block="Table" elem="HeaderData">{__('Order#')}</th>
                    <th block="Table" elem="HeaderData">{__('Pending Qty')}</th>
                    <th block="Table" elem="HeaderData">{__('ETA')}</th>
                </tr>
            </thead>
        );
    }

    renderEta(eta) {
        if (eta == null) return __('Awaiting ETA')
        const { disclaimer } = this.props;

        const etaDate = new Date(eta);
        const now = new Date();

        if (etaDate - (1000 * 60 * 2) < now) {
            return <>{ __('In stock') } - { disclaimer }</>
        }

        return this.parseDate(eta).toLocaleDateString('en-GB');
    }

    renderTableItem(item, index) {
        if (item.itemQty == '0') {
            return null;
        }

        return (
            <tr block="Table" elem="Item" key={index}>
                <td block="Table" elem="ItemData">
                    <span block="Table" elem="Label">{__('Order Date')}</span>
                    {this.parseDate(item.orderDate).toLocaleDateString('en-GB')}
                </td>
                <td block="Table" elem="ItemData" mods={{ color_primaryLight: true }}>
                    <span block="Table" elem="Label">{__('Order#')}</span>
                    <Link block="Table" elem="ItemData" mods={{ color_primaryLight: true }} to={item.orderUrl}>{`#${item.orderId}`}</Link>
                </td>
                <td block="Table" elem="ItemData">
                    <span block="Table" elem="Label">{__('Pending Qty')}</span>
                    {item.itemQty}
                </td>
                <td block="Table" elem="ItemData">
                    <span block="Table" elem="Label">{__('ETA')}</span>
                    {this.renderEta(item.eta)}
                </td>
            </tr>
        );
    }

    parseDate(date) {
        if (date == null) return null
        const dateArr = date.split(/[^0-9]/);
        const parsedDate = new Date(dateArr[0], dateArr[1] - 1, dateArr[2])
        return parsedDate;
    }

    renderTableContent() {
        const {
            payload: {
                product: {
                    orders = []
                } = {}
            } = {}
        } = this.props;

        return (
            <tbody>
                {orders.map((item, index) => this.renderTableItem(item, index))}
            </tbody>
        )
    }

    renderTable() {
        return (
            <table block="Table" mods={{ style_cards: true }} mix={{ block: 'MyAccountProductBacklogPopup', elem: 'Table' }}>
                {this.renderTableHeader()}
                {this.renderTableContent()}
            </table>
        )
    }

    renderActions() {
        return (
            <div block="MyAccountProductBacklogPopup" elem="Actions">
                <div block="MyAccountProductBacklogPopup" elem="ActionsItem">
                    <Link to="/my-account/my-orders?status=open" block="MyAccountProductBacklogPopup" elem="ActionsLink">{__('View all Open Orders')}</Link>
                </div>
                <div block="MyAccountProductBacklogPopup" elem="ActionsItem">
                    <Link to="/my-account/my-orders?status=closed" block="MyAccountProductBacklogPopup" elem="ActionsLink">{__('View all Closed Orders')}</Link>
                </div>
            </div>
        );
    }

    render() {
        return (
            <div block="MyAccountProductBacklogPopup">
                <Popup id={ETA_POPUP_ID} contentMix={{ block: 'Popup', elem: 'Product' }}>
                    <div block="MyAccountProductBacklogPopup" elem="Content">
                        <div block="MyAccountProductBacklogPopup" elem="FlexibleMinWidth" />
                        {this.renderProduct()}
                        {this.renderHeadline()}
                        {this.renderTable()}
                        {this.renderActions()}
                    </div>
                </Popup>
            </div>
        );
    }
}

export default MyAccountProductBacklogPopup;
