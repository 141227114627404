/* eslint-disable */
import SearchField from './SearchField.component';
import { connect } from 'react-redux';
// import { mapDispatchToProps } from 'SourceComponent/SearchField/SearchField.container';

/** @namespace Component/SearchField/Container/mapDispatchToProps */
// eslint-disable-next-line no-unused-vars
export const mapDispatchToProps = (dispatch) => ({});

/** @namespace Component/SearchField/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
    code: state.ConfigReducer.code
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchField);