/* eslint-disable */
import { MyAccountAddressTable as SourceMyAccountAddressTable } from 'SourceComponent/MyAccountAddressTable/MyAccountAddressTable.component';
export * from 'SourceComponent/MyAccountAddressTable/MyAccountAddressTable.component';

import Loader from 'Component/Loader';

import './MyAccountAddressTable.override.style';

/** @namespace Component/MyAccountAddressTable/Component */
export class MyAccountAddressTable extends SourceMyAccountAddressTable {
    get dataPairArray() {
        const { address } = this.props;

        return [
            ...super.dataPairArray,
            {
                key: 'company',
                label: __('Company'),
                source: address
            }
        ]
    }

    renderTableRow = (data) => {
        const { key, label } = data;
        const value = this.getValueFromSource(data);
        // return
        if (!value) {
            return null;
        }

        return (
            <tr key={ key } block="Table" elem="Item">
                <th block="Table" elem="ItemData" mods={{ fontWeight_bold: true, align_left: true }}>{ label }</th>
                <td block="Table" elem="ItemData" mods={{ align_left: true }}>{ value }</td>
            </tr>
        );
    };

    renderHeading() {
        const { title } = this.props;
        if (!title) {
            return null;
        }

        return (
            <tr block="Table" elem="Header">
                <th
                  block="Table"
                  elem="HeaderData"
                  mods={{ align_left: true }}
                  mix={{ block: 'MyAccountAddressTable', elem: 'TableTitle' }}
                  colSpan={ 2 }
                >
                    { title }
                </th>
            </tr>
        );
    }

    renderTable() {
        return (
            <div block="MyAccountAddressTable" elem="Wrapper">
                <table block="Table" mods={{ style_onecard: true, style_onecardForceFull: true }}>
                    <thead>
                        { this.renderHeading() }
                    </thead>
                    <tbody>
                        { this.dataPairArray.map(this.renderTableRow) }
                    </tbody>
                </table>
            </div>
        );
    }

    renderActions() {
        const {
            onEditClick,
            onDeleteClick,
            showActions,
            address: { default_billing, default_shipping }
        } = this.props;

        const isDeleteAllowed = default_shipping || default_billing;
        const isEditNotAllowed = default_billing;

        if (!showActions) {
            return null;
        }

        return (
            <>
                <button
                  block="Button"
                  onClick={ onEditClick }
                  disabled={ isEditNotAllowed }
                >
                    { __('Edit address') }
                </button>
                <button
                  block="Button"
                  mods={ { isHollow: true } }
                  onClick={ onDeleteClick }
                  disabled={ isDeleteAllowed }
                  title={ isDeleteAllowed ? __('Can not delete - address is set as default.') : 'Delete this address' }
                >
                    { __('Delete') }
                </button>
            </>
        );
    }

    render() {
        const { countries, mix } = this.props;

        return (
            <div block="MyAccountAddressTable" mix={ mix }>
                <Loader isLoading={ !countries.length } />
                { this.renderTable() }
                { this.renderActions() }
            </div>
        );
    }
}

export default MyAccountAddressTable;