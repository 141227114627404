import ShippingOptions from "../component/ShippingOptions";
import FormPortal from 'SourceComponent/FormPortal';
import { SHIPPING_STEP } from 'SourceRoute/Checkout/Checkout.config';
import ShippingOptionsComponent from "../component/ShippingOptions/ShippingOptions.component";
import ShippingOptionsContainer from "../component/ShippingOptions/ShippingOptions.container";
import ShippingOptionsReducer from "../store/ShippingOptions/ShippingOptions.reducer";

const addReducer = (args, callback, instance) => {
    return {
        ...callback(...args),
        ShippingOptionsReducer
    }
}

const addShippingOptions = (args, callback, instance) => {
    return (
        <>
            {callback(...args)}
            <ShippingOptions />
            {/* <ShippingOptionsComponent /> */}
            {/* <h1>Hello There</h1> */}
        </>
    );
}

export default {
    'Component/CheckoutShipping/Component': {
        'member-function': {
            'renderAddressBook': addShippingOptions
        }
    },
    'Store/Index/getReducers': {
        'function': addReducer
    }
}