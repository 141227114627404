import { Loader as SourceLoader } from 'SourceComponent/Loader/Loader.component';

import PropTypes from 'prop-types';
import { MixType } from 'Type/Common';

import './Loader.override.style';

export class Loader extends SourceLoader {
    static propTypes = {
        isLoading: PropTypes.bool.isRequired,
        mix: MixType
    };

    defaultProps = {
        mix: {}
    };

    renderMain() {
        const mix = this.props;

        return (
            <div block="Loader" elem="Main" mix={ mix }>
                <span />
            </div>
        );
    }

    render() {
        const { isLoading } = this.props;
        const mix = this.props;

        if (!isLoading) {
            return null;
        }

        return (
            <div block="Loader" mix={ mix }>
                <div block="Loader" elem="Scale">
                    { this.renderMain() }
                </div>
            </div>
        );
    }
}

export default Loader;
