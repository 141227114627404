/* eslint-disable */
import { StoreSwitcherContainer as SourceStoreSwitcherContainer, mapStateToProps, mapDispatchToProps } from 'SourceComponent/StoreSwitcher/StoreSwitcher.container';
import { connect } from 'react-redux';
export * from 'SourceComponent/StoreSwitcher/StoreSwitcher.container';

/** @namespace Component/StoreSwitcher/Container */
export class StoreSwitcherContainer extends SourceStoreSwitcherContainer {
    _formatStoreList(storeList) {
        return storeList.reduce((acc, {
            name, code, is_active, base_url, base_link_url
        }) => {
            if (!is_active) {
                return acc;
            }

            return [
                ...acc,
                {
                    id: `store_${ code }`,
                    value: code,
                    storeUrl: base_url,
                    storeLinkUrl: base_link_url,
                    label: name
                }
            ];
        }, []);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StoreSwitcherContainer);