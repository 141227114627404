/* eslint-disable */
import { Field } from 'Util/Query';

/**
 * DatesInStock Query
 * @class MailchimpQuery
 * @namespace Query/Mailchimp
 */
export class MailchimpQuery {
    __construct() {
        super.__construct();
        this.options = {};
    }

    getQuery(options = {}) {
        this.options = options;

        return new Field('getMailchimpCampaigns')
            .addFieldList(this._getDefaultFields());
    }

    _getDefaultFields() {
        return [
            'title',
            'date',
            'archive_url'
        ];
    }
}

export default new MailchimpQuery();