/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import PropTypes from 'prop-types';

import KeyValueTable from 'Component/KeyValueTable';
import { customerType } from 'Type/Account';

import './MyAccountCustomerTable.override.style';

/** @namespace Component/MyAccountCustomerTable/Component */
export class MyAccountCustomerTable extends KeyValueTable {
    static propTypes = {
        customer: customerType.isRequired,
        showEditPopup: PropTypes.func.isRequired,
        showChangePasswordPopup: PropTypes.func.isRequired
    };

    get dataPairArray() {
        const { customer } = this.props;

        return [
            {
                key: 'firstname',
                label: __('First name'),
                source: customer
            },
            {
                key: 'lastname',
                label: __('Last name'),
                source: customer
            },
            {
                key: 'email',
                label: __('Email'),
                source: customer
            }
        ];
    }

    renderTableRow = (data) => {
        const { key, label } = data;
        const value = this.getValueFromSource(data);

        if (!value) {
            return null;
        }

        return (
            <tr key={ key } block="Table" elem="Item">
                <th block="Table" elem="ItemData" mods={{ fontWeight_bold: true, align_left: true }}>{ label }</th>
                <td block="Table" elem="ItemData" mods={{ align_left: true }}>{ value }</td>
            </tr>
        );
    };

    renderHeading() {
        const { title } = this.props;
        if (!title) {
            return null;
        }

        return (
            <tr block="Table" elem="Header">
                <th
                  block="Table"
                  elem="HeaderData"
                  mods={{ align_left: true }}
                  mix={{ block: 'MyAccountCustomerTable', elem: 'TableTitle' }}
                  colSpan={ 2 }
                >
                    { title }
                </th>
            </tr>
        );
    }

    renderTable() {
        return (
            <div block="MyAccountCustomerTable" elem="Wrapper">
                <table block="Table" mods={{ style_onecard: true, style_onecardForceFull: true }}>
                    <thead>
                        { this.renderHeading() }
                    </thead>
                    <tbody>
                        { this.dataPairArray.map(this.renderTableRow) }
                    </tbody>
                </table>
            </div>
        );
    }

    renderActions() {
        const { showChangePasswordPopup, showEditPopup } = this.props;

        return (
            <>
                <button
                  block="Button"
                  mix={{ block: 'MyAccountCustomerTable', elem: 'Button' }}
                  onClick={ showEditPopup }
                >
                    { __('Edit details') }
                </button>
                <button
                  block="Button"
                  mods={ { isHollow: true } }
                  mix={{ block: 'MyAccountCustomerTable', elem: 'Button' }}
                  onClick={ showChangePasswordPopup }
                >
                    { __('Change password') }
                </button>
            </>
        );
    }

    render() {
        return (
            <div block="MyAccountCustomerTable">
                { this.renderTable() }
                { this.renderActions() }
            </div>
        );
    }
}

export default MyAccountCustomerTable;
